<form
  class="row position-relative"
  (mouseover)="focusEvent$.next(true)"
  (mouseout)="focusEvent$.next(false)"
  *ngIf="{
    dict: autocompleteValue$ | async
  } as state"
>
  <label
    [ngClass]="[
      labelSize ? 'col-sm-' + labelSize : 'col-sm-12',
      control.disabled || isDisabled || isReadonly || !isLoaded ? 'label_disabled' : '',
      'col-form-label',
      'position-relative'
    ]"
    [attr.for]="id"
    *ngIf="label"
  >
    {{ label }}
    <ng-container *ngIf="tooltip">
      <mdb-icon far icon="question-circle" [mdbTooltip]="tooltip" #tooltipDirective="mdb-tooltip" class="mx-1"></mdb-icon>
    </ng-container>
    <div class="position-absolute" style="top: 5px; right: -10px">
      <ng-container *ngIf="diff$ | async as diff">
        <div *ngIf="diff">
          <ng-template #diffTemplate>
            <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(diff)"></div>
          </ng-template>

          <mdb-icon fas icon="edit" [mdbTooltip]="diffTemplate" #tooltipDirective="mdb-tooltip" class="red-text mx-1"></mdb-icon>
        </div>
      </ng-container>
    </div>
  </label>
  <div [ngClass]="[label ? 'col-sm-' + size : 'col-sm-12']">
    <form
      class="input-group md-form mt-0 mb-0 rpn-autocomplete"
      [mdbTooltip]="control.value"
      placement="top"
      [ngClass]="{ 'rpn-input-with-refresh-button': showClearButton }"
      *ngIf="!control.disabled"
      (appClickOutside)="clickOutside()"
    >
      <div *ngIf="!isLoaded" class="rpn-skeleton form-control position-absolute h-100">&nbsp;</div>

      <label *ngIf="labelTop" [for]="id" class="active">
        {{ labelTop }}
        <span *ngIf="tooltip">
          <mdb-icon far icon="question-circle" class="mx-2" [mdbTooltip]="tooltip" #tooltipDirective="mdb-tooltip"></mdb-icon>
        </span>
        <ng-container *ngIf="diff$ | async as diff">
          <span *ngIf="diff">
            <ng-template #diffTemplate>
              <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(diff)"></div>
            </ng-template>

            <mdb-icon fas icon="edit" [mdbTooltip]="diffTemplate" #tooltipDirective="mdb-tooltip" class="red-text mx-1"></mdb-icon>
          </span>
        </ng-container>
      </label>

      <textarea
        *ngIf="category === InputCategory.textarea"
        mdbInput
        [rows]="rowsCount"
        #input
        [id]="id"
        [formControl]="likeFormControl(control)"
        class="md-textarea md-textarea-auto form-control"
        [mdbAutoCompleter]="auto"
        [placeholder]="minChars ? 'Введите не менее ' + minChars + ' символов и выберите из списка' : 'Начните ввод и выберите из списка'"
        (focus)="onFocus($event)"
        (blur)="onBlur($event)"
        (clearBtnClicked)="onClearButtonClick()"
        [required]="required"
      >
      </textarea>

      <input
        *ngIf="category === InputCategory.input"
        mdbInput
        #input
        [id]="id"
        [formControl]="likeFormControl(control)"
        [type]="EInputType.text"
        [class.form-control--filled]="filled"
        [ngClass]="['b3-test_' + formControlName, 'completer-input form-control mdb-autocomplete mb-0']"
        [mdbAutoCompleter]="auto"
        [placeholder]="minChars ? 'Введите не менее ' + minChars + ' символов и выберите из списка' : 'Начните ввод и выберите из списка'"
        (focus)="onFocus($event)"
        (blur)="onBlur($event)"
        (input)="resetFias()"
        (clearBtnClicked)="onClearButtonClick()"
        [required]="required"
      />

      <app-template-button
        *ngIf="!!(templateTags?.groupTag && templateTags?.formTag && formControlName)"
        [currentValue]="{ value: formControl.value, caption: control.value }"
        [isHidded]="formControl.disabled && control.disabled && !isLoaded"
        [groupTag]="templateTags.groupTag"
        [formTag]="templateTags.formTag"
        [fieldTag]="formControlName"
        [fieldLabel]="label"
        (selectItemValueEmit)="selectItemFromTemplate($event)"
      >
      </app-template-button>

      <mdb-auto-completer #auto="mdbAutoCompleter" [appendToBody]="false" (selected)="selectItemValue($event, state.dict)">
        <mdb-option *ngFor="let item of state.dict" [value]="item">
          <div [innerHtml]="item?.highlight" class="w-100 text-left"></div>
        </mdb-option>
      </mdb-auto-completer>

      <ng-container *ngIf="hasFiasInfo && fcd.form.enabled">
        <ng-container *ngIf="fias_info$ | async as fias_info">
          <ng-container *ngIf="isLoaded && !showClearButton">

            <div *ngIf="fias_info.fias_id; else emptyFiasTemplate" #fias class="rpn-fias rpn-fias__find">
              <app-icon [name]="IconsSet.checkCircle2" [width]="'18px'" [height]="'18px'"></app-icon>
              <span class="rpn-fias--title">Адрес найден в ФИАС</span>
            </div>

            <ng-template #emptyFiasTemplate>
              <div #fias class="rpn-fias rpn-fias__not-find">
                <app-icon [name]="IconsSet.alertOctagon" [width]="'18px'" [height]="'18px'"></app-icon>
                <span class="rpn-fias--title">Адрес не найден в ФИАС</span>
              </div>
            </ng-template>

          </ng-container>
        </ng-container>
      </ng-container>
    </form>

    <div class="mb-2rem">
      <app-form-field-validation
        *ngIf="!isFilter && formControl && formControl.enabled && control.enabled && isLoaded && !hasFiasInfo"
        [errors]="formControl.errors"
        [dirty]="formControl.dirty"
        [nonImportantErrors]="nonImportantErrors"
        [notSavedDataMessage]="notSavedDataMessage"
        [valid]="formControl.valid"
        [value]="formControl.value"
      />
    </div>

    <form class="input-group md-form mt-0" [mdbTooltip]="control.value" placement="top" *ngIf="control.disabled">
      <div *ngIf="!isLoaded" class="rpn-skeleton form-control h-auto position-absolute" style="min-height: 44px">&nbsp;</div>
      <input
        [id]="id"
        [formControl]="likeFormControl(control)"
        [class.form-control--filled]="filled"
        [type]="EInputType.text"
        [ngClass]="['b3-test_' + formControlName, 'form-control mb-0']"
      />
      <label *ngIf="labelTop" [for]="id" class="active">
        {{ labelTop }}
        <span *ngIf="tooltip">
          <mdb-icon far icon="question-circle" class="mx-2" [mdbTooltip]="tooltip" #tooltipDirective="mdb-tooltip"></mdb-icon>
        </span>
        <ng-container *ngIf="diff$ | async as diff">
          <span *ngIf="diff">
            <ng-template #diffTemplate>
              <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(diff)"></div>
            </ng-template>

            <mdb-icon fas icon="edit" [mdbTooltip]="diffTemplate" #tooltipDirective="mdb-tooltip" class="red-text mx-1"></mdb-icon>
          </span>
        </ng-container>
      </label>
    </form>

    <ng-container *ngIf="(auth.scopeRepo$ | async).admin.isActive">
      <ng-container *ngIf="{isVisible: isTooltipeVisible$ | async} as state">

        <div *ngIf="state.isVisible && formControl.enabled && control.enabled && isLoaded"
          class="tooltip__helper"
          (mouseover)="focusEvent$.next(true)"
          (mouseout)="focusEvent$.next(false)">
          <mdb-icon far icon="question-circle"
            [mdbTooltip]="'Начните ввод для последующего автоматического заполнения'"
            #tooltipDirective="mdb-tooltip" class="mx-1">
          </mdb-icon>
        </div>

      </ng-container>
    </ng-container>
  </div>

</form>
