<div class="row position-relative"
  (mouseover)="focusEvent$.next(true)"
  (mouseout)="focusEvent$.next(false)">

  <label
    [ngClass]="[
      labelSize ? 'col-sm-' + labelSize : 'col-sm-12',
      control.disabled || isDisabled || isReadonly || !isLoaded || !isDictionaryItemLoaded ? 'label_disabled' : '',
      'col-form-label',
      'position-relative'
    ]"
    [attr.for]="id" *ngIf="label">
    {{label}}
    <ng-container *ngIf="tooltip">
      <mdb-icon far icon="question-circle" [mdbTooltip]="tooltip" #tooltipDirective="mdb-tooltip" class="mx-1"></mdb-icon>
    </ng-container>
    <div class="position-absolute" style="top:5px; right: -10px;">

      <ng-container *ngIf="diff$ | async as diff">
        <div *ngIf="diff">
          <ng-template #diffTemplate>
            <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(diff)"></div>
          </ng-template>

          <mdb-icon fas icon="edit" [mdbTooltip]="diffTemplate" #tooltipDirective="mdb-tooltip" class="red-text mx-1"></mdb-icon>
        </div>
      </ng-container>
    </div>
  </label>
  <div [ngClass]="[label ? 'col-sm-' + size : 'col-sm-12']">
    <form class="input-group md-form mt-0 rpn-select-container" (appClickOutside)="clickOutside()">
      <div *ngIf="!isLoaded || !isDictionaryItemLoaded" class="rpn-skeleton form-control position-absolute h-auto" style="min-height: 44px;">&nbsp;</div>
      <label *ngIf="labelTop" class="active" [mdbTooltip]="labelTop">{{labelTop}}</label>

      <div class="d-flex align-items-center w-100">
        <mdb-select
          #selectComponent
          [class.form-control--filled]="filled"
          [appendToBody]="false"
          [id]="id"
          [formControl]="likeFormControl(control)"
          [options]="options$ | async"
          [placeholder]="getPlaceholder()"
          [required]="required"
          (selected)="selectValue($event)"
          [ngClass]="['b3-test_' + formControlName, showSuggestOnTop ? 'rpn-top-suggest' : '']">
        </mdb-select>

        <mdb-icon fas icon="times" style="font-size: 10px; cursor: pointer;" (click)="resetValue()"
                  *ngIf="removeButton && !isFilter && control.enabled && control.value"></mdb-icon>
        <ng-content></ng-content>
      </div>

      <app-form-field-validation
        *ngIf="!isFilter && formControl && formControl.enabled && control.enabled && isLoaded && isDictionaryItemLoaded"
        [errors]="formControl.errors"
        [dirty]="formControl.dirty"
        [notSavedDataMessage]="notSavedDataMessage"
        [valid]="formControl.valid"
        [value]="formControl.value">
      </app-form-field-validation>
    </form>

    <ng-container *ngIf="(auth.scopeRepo$ | async).admin.isActive">
      <ng-container *ngIf="{isVisible: isTooltipeVisible$ | async} as state">

        <div *ngIf="state.isVisible && formControl.enabled && control.enabled && isLoaded"
          class="tooltip__helper"
          (mouseover)="focusEvent$.next(true)"
          (mouseout)="focusEvent$.next(false)">
          <mdb-icon far icon="question-circle"
            [mdbTooltip]="'Выбор возможен только из списка'"
            #tooltipDirective="mdb-tooltip" class="mx-1">
          </mdb-icon>
        </div>

      </ng-container>
    </ng-container>

  </div>

</div>
