import { GECVAuthorType } from 'src/app/modules/details-edit-mode/gee-estimated-costs-validation/gecv-author/gecv-author.component';
import {
  ERefundBudget,
  IRefundBudgetDictionary,
} from 'src/app/modules/details-edit-mode/refund-requests/interfaces/refund-request.interfaces';
import { BadgeStatus } from 'src/app/modules/details-edit-mode/shared-sections/smev3/smev3.interface';
import { MapGeometryType } from 'src/app/modules/map.component/map.const';
import {
  EGisGmpPayerType,
  EPayerDocumentCode,
  EPayerType,
  IPaymentsManagementGisGmpPayerTypeDictionaryItem,
  IPaymentsManagementPayerDocumentCodeDictionaryItem,
  IPaymentsManagementPayerTypeDictionaryItem,
} from 'src/app/pages/pm/pm-common/charge-view/interfaces/charge-view.interfaces';
import {
  EGisGmpRequestStatus,
  EGisGmpRequestStatusText,
  EGisGmpRequestType,
  EGisGmpRequestTypeText,
  EGisGmpStatus,
  EGisGmpStatusText,
  PM_ACCRUALS_payment_status,
  PM_PAYMENTS_reconciliation_statuses,
} from 'src/app/pages/pm/pm-common/interfaces/gis-gmp.interface';
import { EXmlEbName, EXmlEbType, IXmlEbTypeDictItem } from 'src/app/pages/pm/pm-ksv/interfaces/xml-eb.interface';
import {
  EPaymentsManagementAnalyticsReportFormTypeKeys,
  EPaymentsManagementAnalyticsReportStatusKeys,
  IPMAnalyticsFormEnvironmentComponentName,
  IPMAnalyticsReportCreationMode,
  IPaymentsManagementAnalyticsFormType,
  IPaymentsManagementAnalyticsReportStatus,
} from 'src/app/pages/pm/pm-ksv/payments-management-analytics/interfaces/payments-management-analytics-form.interface';
import {
  EPmOpeningBalanceImportsParseStatus,
  EPmOpeningBalanceImportsParseStatusKeys,
} from 'src/app/pages/pm/pm-ksv/pm-opening-balance/interfaces/pm-opening-balance.interface';
import {
  EPMSufdImportsParseStatusKeys,
  EPMSufdImportsSufdTypeKeys,
  EPMVtOperationsDocCodeFiltersKeys,
  EPMVtOperationsDocCodeKeys,
  EPMVtOperationsUfTypeKeys,
  IPMSufdImportsBudgetLevel,
  IPMSufdImportsExtractKind,
  IPMSufdImportsParseStatus,
  IPMSufdImportsSufdType,
  IPMVtOperationsDocCode,
  IPMVtOperationsDocCodeFilters,
  IPMVtOperationsUfType,
} from 'src/app/pages/pm/pm-ksv/pm-sufd/sections/pm-sufd-imports/interfaces/pm-sufd-imports.interface';
import { EDocType, EDocTypeForFilter } from 'src/app/providers/_const/fields.const';
import { SuperVisionLevelsEnum, SmevUIStatus, EErvkStatusSync } from 'src/app/providers/_enum';
import { ECheckListAnswerValue } from 'src/app/providers/_interfaces/check-list.interface';
import { IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';
import { IPrDeistv } from 'src/app/providers/_interfaces/licenses.interface';
import { UITextO } from 'src/app/providers/_text/onvos';

export const StaticDictValues = {
  operating_mode: <IDictionaryItem[]>[
    { id: 'test', name: 'Тестовая эксплуатация' },
    { id: 'production', name: 'Промышленная эксплуатация' },
  ],

  excluded_options: <IDictionaryItem[]>[
    { id: 'is_registered', name: 'Состоит на учете' },
    { id: 'is_removed', name: 'Снят с учета' },
    { id: 'is_automatically_excluded_2024', name: 'Сведения автоматически исключены (п. 2 ПП РФ от 15.04.2024 № 473)' },
    { id: 'is_automatically_excluded_2022', name: 'Сведения автоматически исключены (п. 48(1) ПП РФ от 07.05.2022 № 830)' },
    { id: 'is_excluded_2022', name: 'Сведения исключены (п. 48 ПП РФ от 07.05.2022 № 830)' },
    { id: 'is_auto_verificated', name: 'Состоит на учете (сведения включены в режиме 24/7)'}
  ],

  active_status: <IDictionaryItem[]>[
    { id: true, name: 'Активен' },
    { id: false, name: 'Неактивен' },
  ],
  
  profile_item_acceptable: <IDictionaryItem[]>[
    { id: 'true', name: 'Разблокировано' },
    { id: 'false', name: 'Заблокировано' },
  ],

  secret_registry: <IDictionaryItem[]>[
    { id: 'true', name: 'Только режимные объекты' },
    { id: 'false', name: 'За исключением режимных объектов' },
  ],

  document_registry_status: <IDictionaryItem[]>[
    { name: 'Черновик', id: 'draft' },
    { name: 'Опубликованный', id: 'published' },
    { name: 'Неактуальный', id: 'inactive' },
  ],

  document_registry_system_types: <IDictionaryItem[]>[
    { id: 'ksv', name: 'Кабинет сотрудника ведомства' },
    { id: 'lk', name: 'Личный кабинет природопользователя' },
    { id: 'onvos', name: 'ОНВОС' },
    { id: 'uonvos', name: 'УОНВОС' },
  ],

  seal_state: <IDictionaryItem[]>[
    { name: 'Не нарушено', id: 'OK' },
    { name: 'Нарушено для технических работ', id: 'MAINTENANCE' },
    { name: 'Нарушено', id: 'ERROR' },
  ],

  ams_state: <IDictionaryItem[]>[
    { name: 'АСИ работает исправно', id: 'OK' },
    { name: 'АСИ находится в процессе технического обслуживания', id: 'MAINTENANCE' },
    { name: 'В работе АСИ возникли ошибки', id: 'ERROR' },
  ],

  erul_status: <IDictionaryItem[]>[
    { name: 'Ожидается получение номера в ЕРУЛ', id: 'erul_number_waiting' },
    { name: 'Получен номер в ЕРУЛ', id: 'erul_number_received' },
    { name: 'Ожидается направление дополнительной информации в ЕРУЛ', id: 'erul_additional_info_waiting' },
    { name: 'Направлена дополнительная информация в ЕРУЛ', id: 'erul_additional_info_directed' },
  ],

  outgoing_message_status: <IDictionaryItem[]>[
    { name: 'Ожидает передачи адаптеру', id: 'NEW' },
    { name: 'Отклонено адаптером', id: 'FAULT' },
    { name: 'Отправлено в СМЭВ', id: 'SUCCESS' },
    { name: 'Передано адаптеру', id: 'SENT' },
    { name: 'Ошибка передачи адаптеру', id: 'STUCK' },
    { name: 'Устарело', id: 'STALE' },
    { name: 'Ошибочно', id: 'ERROR' },
    { name: 'Отклонено', id: 'REJECT' },
  ],

  datasource_operators: <IDictionaryItem[]>[
    { name: 'Равно', id: '=' },
    { name: 'Больше', id: '>' },
    { name: 'Меньше', id: '<' },
    { name: 'Меньше-равно', id: '<=' },
    { name: 'Больше-равно', id: '>=' },
    { name: 'Не равно', id: '<>' },
    { name: 'Включает несколько значений', id: 'IN' },
    { name: 'Совпадает (без учета регистра)', id: 'ILIKE' },
    { name: 'Между (включая значения)', id: 'BETWEEN' },
  ],

  datasource: <IDictionaryItem[]>[{ name: 'Да', id: '7,71' }],

  onvos_is_secret: <IDictionaryItem[]>[
    { name: 'Режимный', id: true },
    { name: 'Режимный', id: 'true' },
    { name: 'Нет', id: false },
    { name: 'Нет', id: 'false' },
    { name: 'Нет', id: '' },
  ],

  monitoring_chart_time: <IDictionaryItem[]>[
    { name: 'по месяцам', id: 'month' },
    { name: 'по дням', id: 'day' },
    { name: 'по часам', id: 'hour' },
    { name: 'по факту', id: '' },
  ],

  smev_mode: <IDictionaryItem[]>[
    { name: 'and', id: 'and' },
    { name: 'serial', id: 'serial' },
  ],

  elk_statuses: <IDictionaryItem[]>[
    { name: 'Принято в ЕЛК', id: 'OK' },
    { name: 'Ошибка передачи в ЕЛК', id: 'ERROR' },
  ],

  gecv_outgoings_statuses: <IDictionaryItem[]>[
    { name: 'Черновик', id: 'draft' },
    { name: 'Отправлено', id: 'sent' },
    { name: 'Зарегистрировано', id: 'registered' },
    { name: 'Отказ в приеме', id: 'rejected' },
  ],

  smev_isPril: <IDictionaryItem[]>[
    { name: 'on', id: 'on' },
    { name: 'off', id: 'off' },
  ],

  typePerson: <IDictionaryItem[]>[
    { id: 1, name: 'Декларант' },
    { id: 2, name: 'Получатель' },
    { id: 3, name: 'Контрактодержатель' },
  ],

  citizenshipType: <IDictionaryItem[]>[
    { name: 'для гражданина Российской Федерации', id: 1 },
    { name: 'для иностранного гражданина', id: 2 },
    { name: 'для лица без гражданства', id: 3 },
  ],

  genderType: <IDictionaryItem[]>[
    { name: 'мужской', id: 1 },
    { name: 'женский', id: 2 },
  ],

  genderTypeEng: <IDictionaryItem[]>[
    { name: 'мужской', id: 'male' },
    { name: 'женский', id: 'female' },
  ],

  identityType: <IDictionaryItem[]>[
    { name: 'ЮЛ или ИП', id: '00' },
    { name: 'Паспорт гражданина Российской Федерации', id: '01' },
    { name: 'Свидетельство органов ЗАГС, органа исполнительной власти или органа местного самоуправления о рождении гражданина', id: '02' },
    { name: 'Паспорт моряка (удостоверение личности моряка)', id: '03' },
    { name: 'Удостоверение личности военнослужащего', id: '04' },
    { name: 'Военный билет военнослужащего', id: '05' },
    { name: 'Временное удостоверение личности гражданина Российской Федерации', id: '06' },
    { name: 'Справка об освобождении из мест лишения свободы', id: '07' },
    {
      name: `Паспорт иностранного гражданина либо иной документ,
      соответствии с международным договором Российской Федерации в качестве документа,
      удостоверяющего личность иностранного гражданина`,
      id: '08',
    },
    { name: 'Вид на жительство', id: '09' },
    { name: 'Разрешение на временное проживание (для лиц без гражданства)', id: '10' },
    { name: 'Удостоверение беженца', id: '11' },
    { name: 'Миграционная карта', id: '12' },
    { name: 'Паспорт гражданина СССР', id: '13' },
    { name: 'CНИЛС', id: '14' },
    { name: 'Удостоверение личности гражданина Российской Федерации', id: '15' },
    { name: 'ИНН', id: '21' },
    { name: 'Водительское удостоверение', id: '22' },
    { name: 'Свидетельство о регистрации транспортного средства в органах Министерства внутренних дел Российской Федерации', id: '24' },
    { name: 'Охотничий билет', id: '25' },
    { name: 'Разрешение на хранение и ношение охотничьего оружия', id: '26' },
    {
      name: `Паспорт гражданина Российской Федерации, являющийся основным документом,
      удостоверяющим личность гражданина Российской Федерации за пределами территории Российской Федерации,
      в том числе содержащий электронный носитель информации`,
      id: '28',
    },
    { name: 'Свидетельство о предоставлении временного убежища на территории Российской Федерации', id: '29' },
    { name: 'Свидетельство о рассмотрении ходатайства по существу', id: '30' },
  ],

  reports_filters_status_code: <IDictionaryItem[]>[
    { id: 'draft', name: 'Черновик' },
    { id: 'manage_totals', name: 'Работа с Итогами' },
    { id: 'ready', name: 'Готов к отправке' },
    { id: 'paper_waiting', name: 'Ожидается оригинал' },
    { id: 'to_review', name: 'Ожидает рассмотрения' },
    { id: 'review', name: 'На рассмотрении' },
    { id: 'accepted', name: 'Принято' },
    { id: 'to_canceled', name: 'Запрос отмены' },
    { id: 'imported', name: 'Импортирован' },
    { id: 'deleted', name: 'Удалено' },
    { id: 'declined', name: 'Отклонено' },
    { id: 'canceled', name: 'Отменен заявителем' },
    { id: 'returned', name: 'На доработке' },
    { id: 'redirected', name: 'Перенаправлен' },
  ].map((item) => {
    return { ...item, code: item.id };
  }),

  analytic_filters_status_code: <IDictionaryItem[]>[
    { id: 'draft', name: 'Черновик' },
    { id: 'ready', name: 'Готов к отправке' },
    { id: 'paper_waiting', name: 'Ожидается оригинал' },
    { id: 'to_review', name: 'Ожидает рассмотрения' },
    { id: 'review', name: 'На рассмотрении' },
    { id: 'accepted', name: 'Принят' },
    { id: 'declined', name: 'Отклонен' },
    { id: 'to_canceled', name: 'Запрос отмены' },
    { id: 'canceled', name: 'Отменен заявителем' },
    { id: 'deleted', name: 'Удален' },
  ].map((item) => {
    return { ...item, code: item.id };
  }),

  smev_licence_fns_processing_code: <IDictionaryItem[]>[
    { id: '13', name: 'Сведения о лицензиате не найдены в реестре' },
    {
      id: '14',
      name: `Неизвестный, или недействующий,
      или повторяющийся код вида деятельности, на который выдана лицензия, по справочнику СЛВД`,
    },
    { id: '16', name: 'Отсутствуют ранее переданные сведения с указанным идентификатором документа' },
    { id: '17', name: 'Лицензиат прекратил деятельность; сведения не подлежат внесению в реестр' },
    { id: '50', name: 'Сведения приняты' },
    { id: '81', name: 'Сведения о лицензирующем органе не найдены в ЕГРЮЛ' },
    { id: '82', name: 'Ошибка форматно-логического контроля' },
    { id: '84', name: 'В сведениях о лицензирующем органе указан некорректный ИНН' },
    { id: '85', name: 'В сведениях о лицензиате указан некорректный' },
    { id: '86', name: 'В сведениях о лицензирующем органе указанный ИНН не соответствует указанному ОГРН' },
    { id: '87', name: 'В сведениях о лицензиате указанный ИНН не соответствует указанному ОГРН' },
  ].map((item) => {
    return { ...item, code: item.id };
  }),

  pollutant_hazard_class: <IDictionaryItem[]>[
    { name: '-', id: 0 },
    { name: '1', id: 1 },
    { name: '2', id: 2 },
    { name: '3', id: 3 },
    { name: '4', id: 4 },
  ],

  eek_kinds: <IDictionaryItem[]>[
    // { name: 'Ввоз', id: 'Ввоз', },
    { name: 'Вывоз', id: 'вывоз' },
    // { name: 'Временной ввоз', id: 'Временной ввоз' },
    // { name: 'Временный вывоз', id: 'Временный вывоз' },
    // { name: 'Транзит', id: 'Транзит' },
  ],

  eek_duplicate_reasons: <IDictionaryItem[]>[
    { name: 'Порча', id: 'порча' },
    { name: 'Утеря', id: 'утеря' },
  ],

  ker_application_type: <IDictionaryItem[]>[
    { name: 'Выдача разрешения', id: 'Выдача разрешения' },
    { name: 'Переоформление разрешения', id: 'Переоформление разрешения' },
    { name: 'Пересмотр разрешения', id: 'Пересмотр разрешения' },
    { name: 'Внесение изменений в разрешение', id: 'Внесение изменений в разрешение' },
    { name: 'Исправление технических ошибок в разрешении', id: 'Исправление технических ошибок в разрешении' },
    { name: 'Выдача дубликата разрешения', id: 'Выдача дубликата разрешения' },
    { name: 'Отзыв разрешения', id: 'Отзыв разрешения' },
  ],

  revise_params_option: <IDictionaryItem[]>[
    { name: 'выдать лично на руки', id: 'выдать лично на руки' },
    { name: 'отправить почтой', id: 'отправить почтой' },
    { name: 'отправить по телекоммуникационным каналам связи', id: 'отправить по телекоммуникационным каналам связи' },
  ],

  refund_conclusion: <IDictionaryItem[]>[
    { name: 'Положительное', id: 'positive' },
    { name: 'Отрицательное', id: 'negative' },
  ],

  refund_budget: <IRefundBudgetDictionary[]>(
    Object.keys(ERefundBudget).map((key) => ({ id: ERefundBudget[<ERefundBudget>key], name: ERefundBudget[<ERefundBudget>key] }))
  ),

  doc_public_expertises: <IDictionaryItem[]>[
    { name: 'Проводилась', id: 'Проводилась' },
    { name: 'Не проводилась', id: 'Не проводилась' },
  ],

  payment_status: <IDictionaryItem[]>PM_ACCRUALS_payment_status,

  payment_status_filter_table: <IDictionaryItem[]>[
    { id: 'paid', name: 'оплачено' },
    { id: 'partly_paid', name: 'частично оплачено' },
    { id: 'not_paid', name: 'не оплачено' },
    { id: 'written_off', name: 'списано' },
  ],

  payments_management_state_services: <IDictionaryItem[]>[
    { name: 'Декларация о плате за НВОС (форма приказа 1043)', id: 610 },
    { name: 'Декларация о плате за НВОС (форма приказа 1043)', id: 624 },
    { name: 'Декларация о плате за НВОС', id: 61 },
    { name: 'Корректировка декларации о плате за НВОС', id: 72 },
    { name: 'Декларации о плате за НВОС (КСВ)', id: 73 },
    { name: 'Декларации о плате за НВОС, итоги (КСВ)', id: 74 },
    { name: 'Расчет экосбора', id: 59 },
  ],

  payments_management_state_services_dpnvos: <IDictionaryItem[]>[
    { name: 'Декларация о плате за НВОС (форма приказа 1043)', id: 610 },
    { name: 'Декларация о плате за НВОС (форма приказа 624)', id: 611 },
    { name: 'Декларация о плате за НВОС', id: 61 },
    { name: 'Корректировка декларации о плате за НВОС', id: 72 },
    { name: 'Декларации о плате за НВОС (КСВ)', id: 73 },
    { name: 'Декларации о плате за НВОС, итоги (КСВ)', id: 74 },
  ],

  payments_management_state_services_eco: <IDictionaryItem[]>[{ name: 'Расчет экосбора', id: 59 }],
  payments_management_state_services_other_documents: <IDictionaryItem[]>[{ name: 'Требования о доначислении', id: 615 }],
  pm_state_services: <IDictionaryItem[]>[
    { id: 615, name: 'Требования о доначислении', },
    { id: 570, name: 'Постановления', },
  ],

  payments_management_analytics_report_statuses: <IPaymentsManagementAnalyticsReportStatus[]>[
    { id: EPaymentsManagementAnalyticsReportStatusKeys.draft, name: 'Черновик' },
    { id: EPaymentsManagementAnalyticsReportStatusKeys.confirmed, name: 'Подтвержден' },
  ],

  payments_management_analytics_form_types: <IPaymentsManagementAnalyticsFormType[]>[
    { id: EPaymentsManagementAnalyticsReportFormTypeKeys.form_2, name: 'Форма №2' },
    { id: EPaymentsManagementAnalyticsReportFormTypeKeys.form_3, name: 'Форма №3' },
    { id: EPaymentsManagementAnalyticsReportFormTypeKeys.form_4, name: 'Форма №4' },
    { id: EPaymentsManagementAnalyticsReportFormTypeKeys.form_6, name: 'Форма №6' },
    { id: EPaymentsManagementAnalyticsReportFormTypeKeys.form_9, name: 'Форма №9' },
    { id: EPaymentsManagementAnalyticsReportFormTypeKeys.form_10, name: 'Форма №10' },
  ],

  pm_analytics_form_environment_component_name: <IPMAnalyticsFormEnvironmentComponentName[]>[
    { id: 0, name: 'Водный объект' },
    { id: 1, name: 'Почва' },
    { id: 2, name: 'Недра' },
    { id: 3, name: 'Атмосферный воздух' },
    { id: 4, name: 'Флора' },
    { id: 5, name: 'Фауна' },
  ],

  payments_management_payer_type: <IPaymentsManagementPayerTypeDictionaryItem[]>[
    { id: EPayerType.private, name: 'Физическое лицо' },
    { id: EPayerType.legal, name: 'Юридическое лицо' },
    { id: EPayerType.individual, name: 'Индивидуальный предприниматель' },
    { id: EPayerType.nonresident_legal, name: 'Юридическое лицо, нерезидент' },
    { id: EPayerType.absent, name: 'Не определено' },
  ],

  payments_management_payer_document_code: <IPaymentsManagementPayerDocumentCodeDictionaryItem[]>[
    { id: EPayerDocumentCode.pasport_rf, name: 'Паспорт РФ' },
    { id: EPayerDocumentCode.snils, name: 'СНИЛС' },
  ],

  pm_opening_balance_imports_parse_status: <EPmOpeningBalanceImportsParseStatus[]>[
    { id: EPmOpeningBalanceImportsParseStatusKeys.partially, name: 'Загружена часть входящих сальдо' },
    { id: EPmOpeningBalanceImportsParseStatusKeys.all, name: 'Загружены все входящие сальдо' },
    { id: EPmOpeningBalanceImportsParseStatusKeys.duplicated, name: 'Дублируются все входящие сальдо' },
    { id: EPmOpeningBalanceImportsParseStatusKeys.error, name: 'Ошибка' },
  ],

  payments_management_sufd_imports_parse_status: <IPMSufdImportsParseStatus[]>[
    { id: EPMSufdImportsParseStatusKeys.ok, name: 'Успешно' },
    { id: EPMSufdImportsParseStatusKeys.error, name: 'Ошибка' },
    { id: EPMSufdImportsParseStatusKeys.wait, name: 'Ожидает загрузки' },
  ],

  payments_management_sufd_imports_extract_kind: <IPMSufdImportsExtractKind[]>[
    { id: '0', name: 'Нет' },
    { id: '1', name: 'Да' },
  ],

  payments_management_sufd_imports_budget_level: <IPMSufdImportsBudgetLevel[]>[
    { id: '1', name: 'Федеральный бюджет' },
    { id: '2', name: 'Бюджет субъекта РФ' },
    { id: '3', name: 'Местный бюджет' },
    { id: '4', name: 'Бюджет ГВФ РФ' },
    { id: '5', name: 'Бюджет ТГВФ' },
  ],

  payments_management_sufd_imports_sufd_type: <IPMSufdImportsSufdType[]>[
    { id: EPMSufdImportsSufdTypeKeys.vtoper, name: 'Исполненная операция' },
    { id: EPMSufdImportsSufdTypeKeys.vtnoper, name: 'Неисполненная операция' },
  ],

  payments_management_vt_operations_uf_type: <IPMVtOperationsUfType[]>[
    { id: EPMVtOperationsUfTypeKeys.payment_to_create, name: 'Невыясненные поступления' },
    { id: EPMVtOperationsUfTypeKeys.payment_to_change, name: 'Изменение реквизитов' },
  ],

  pm_vt_operations_doc_code_filters: <IPMVtOperationsDocCodeFilters[]>[
    {
      id: EPMVtOperationsDocCodeFiltersKeys.PP,
      name: 'Платежное поручение',
    },
    {
      id: EPMVtOperationsDocCodeFiltersKeys.ZV,
      name: 'Заявка на возврат',
    },
    {
      id: EPMVtOperationsDocCodeFiltersKeys.UF,
      name: 'Уведомление об уточнении',
    },
    {
      id: EPMVtOperationsDocCodeFiltersKeys.PL,
      name: 'Распоряжение о перечислении на счет',
    },
    {
      id: EPMVtOperationsDocCodeFiltersKeys.SF,
      name: 'Справка ТОФК',
    },
    {
      id: EPMVtOperationsDocCodeFiltersKeys.SP,
      name: 'Бухгалтерская справка ф. 0504833',
    },
    {
      id: EPMVtOperationsDocCodeFiltersKeys.RC,
      name: 'Реестр передаваемых (принимаемых) платежей, прилагаемый к выписке из лицевого счета администратора доходов бюджета',
    },
    {
      id: EPMVtOperationsDocCodeFiltersKeys.RD,
      name: 'Реестр передаваемых (принимаемых) платежей, поступивших в бюджет минуя счет территориального органа Федерального казначейства',
    },
    {
      id: EPMVtOperationsDocCodeFiltersKeys.XX,
      name: 'Прочие документы',
    },
  ],

  pm_vt_operations_doc_code: <IPMVtOperationsDocCode[]>[
    {
      id: EPMVtOperationsDocCodeKeys.PP,
      name: 'Платежное поручение',
    },
    {
      id: EPMVtOperationsDocCodeKeys.ZV,
      name: 'Заявка на возврат',
    },
    {
      id: EPMVtOperationsDocCodeKeys.UF,
      name: 'Уведомление об уточнении',
    },
    {
      id: EPMVtOperationsDocCodeKeys.PL,
      name: 'Распоряжение о перечислении на счет',
    },
    {
      id: EPMVtOperationsDocCodeKeys.SF,
      name: 'Справка ТОФК',
    },
    {
      id: EPMVtOperationsDocCodeKeys.SP,
      name: 'Бухгалтерская справка ф. 0504833',
    },
    {
      id: EPMVtOperationsDocCodeKeys.RC,
      name: 'Реестр передаваемых (принимаемых) платежей, прилагаемый к выписке из лицевого счета администратора доходов бюджета',
    },
    {
      id: EPMVtOperationsDocCodeKeys.RD,
      name: 'Реестр передаваемых (принимаемых) платежей, поступивших в бюджет минуя счет территориального органа Федерального казначейства',
    },
    {
      id: EPMVtOperationsDocCodeKeys.XX,
      name: 'Прочие документы',
    },
    {
      id: EPMVtOperationsDocCodeKeys.IZ,
      name: 'Извещение о поступлении в иностранной валюте',
    },
    {
      id: EPMVtOperationsDocCodeKeys.UN,
      name: 'Уведомление об уточнении, зачете (ФНС)',
    },
  ],

  payments_management_vt_operations_loaded: <IDictionaryItem[]>[
    { id: false, name: 'Не загружено' },
    { id: true, name: 'Загружено' },
  ],

  reconciliation_status_filter: <IDictionaryItem[]>[
    { id: 'reconciled', name: 'сквитирован' },
    { id: 'partly_reconciled', name: 'частично сквитирован' },
    { id: 'not_reconciled', name: 'не сквитирован' },
  ],

  reconciliation_status_with_null_filter: <IDictionaryItem[]>[
    { id: 'reconciled', name: 'Сквитировано' },
    { id: 'partly_reconciled', name: 'Частично сквитировано' },
    { id: 'not_reconciled', name: 'Не сквитировано' },
    { id: 'null', name: 'Не указано' },
  ],

  reconciliation_status: <IDictionaryItem[]>PM_PAYMENTS_reconciliation_statuses,

  applicant_types: <IDictionaryItem[]>[
    { id: GECVAuthorType.legal, name: 'Юридическое лицо' },
    { id: GECVAuthorType.individual, name: 'Индивидуальный предприниматель' },
  ],

  all_applicant_types: <IDictionaryItem[]>[
    { id: 'legal', name: 'Юридическое лицо' },
    { id: 'individual', name: 'Индивидуальный предприниматель' },
    { id: 'private', name: 'Физическое лицо' },
  ],

  applicant_types_with_absent: <IDictionaryItem[]>[
    { id: 'legal', name: 'Юридическое лицо' },
    { id: 'individual', name: 'Индивидуальный предприниматель' },
    { id: 'absent', name: 'Отсутствует' },
  ],

  all_applicant_types_with_absent: <IDictionaryItem[]>[
    { id: 'legal', name: 'Юридическое лицо' },
    { id: 'individual', name: 'Индивидуальный предприниматель' },
    { id: 'private', name: 'Физическое лицо' },
    { id: 'nonresident_legal', name: 'Иностранные юридические лица' },
    { id: 'nonresident_private', name: 'Иностранные физические лица' },
    { id: 'absent', name: 'Отсутствует' },
  ],

  cites_okei_groups: <IDictionaryItem[]>[
    { id: '3', name: 'Объем' },
    { id: '4', name: 'Масса' },
    { id: 'null', name: 'Не указывается' },
  ],

  gee_document_status: <IDictionaryItem[]>[
    { id: 'draft', name: 'Черновик' },
    { id: 'sent', name: 'Отправлено' },
    { id: 'registered', name: 'Зарегистрировано' },
    { id: 'rejected', name: 'Отказ в приеме документов' },
  ],

  dep_lic_requisite_types: <IDictionaryItem[]>[
    { name: 'ИНН', id: 'inn' },
    { name: 'ОГРН', id: 'ogrn' },
    { name: 'Номер лицензии', id: 'license_number' },
  ],

  controlled_entity_statuses: <IDictionaryItem[]>[
    { name: 'Строящийся', id: 'building' },
    { name: 'Законсервированный', id: 'mothballed' },
    { name: 'Ликвидирован', id: 'liquidated' },
    { name: 'Действующий', id: 'active' },
    { name: 'Недействующий', id: 'inactive' },
  ],

  payments_analytics_form_6_periods: <IDictionaryItem[]>[
    { id: '1-е апреля', name: '1-е апреля' },
    { id: '1-е августа', name: '1-е августа' },
    { id: '5-е декабря', name: '5-е декабря' },
  ],

  payments_management_xml_eb_type: <IXmlEbTypeDictItem[]>[
    { id: EXmlEbType.accruals, name: EXmlEbName.accruals },
    { id: EXmlEbType.reconciliations, name: EXmlEbName.reconciliations },
    { id: EXmlEbType.statement, name: EXmlEbName.statement },
    { id: EXmlEbType.group_statement, name: EXmlEbName.group_statement },
    { id: EXmlEbType.statement_lost_income, name: EXmlEbName.statement_lost_income },
  ],

  pm_ksv_declaration_statuses: <IDictionaryItem[]>[
    { id: 'done', name: 'Отправлен' },
    { id: 'canceled', name: 'Отменен' },
    { id: 'formed', name: 'Сформировано' },
    { id: 'directed_execution', name: 'Направлено для исполнения' },
    { id: 'unchanged', name: 'Оставлено без изменений' },
    { id: 'reduced_amount_fine', name: 'Снижена сумма штрафа' },
    { id: 'executed', name: 'Исполнено' },
    { id: 'fine', name: 'Из постановления' },
  ],

  payments_management_declarations_zero: <IDictionaryItem[]>[
    { id: false, name: 'Ненулевые' },
    { id: true, name: 'Нулевые' },
  ],

  payments_management_accruals_canceled: <IDictionaryItem[]>[
    { id: false, name: 'Неотмененные' },
    { id: true, name: 'Отмененные' },
  ],

  payments_management_report_parameters: <IDictionaryItem[]>[
    { id: 'to', name: 'Плательщику, Органу РПН' },
    { id: 'to_region', name: 'Плательщику, Органу РПН, Субъекту РФ' },
    { id: 'to_region_kbk', name: 'Плательщику, Органу РПН, Субъекту РФ, КБК' },
  ],

  supervision_levels: <IDictionaryItem[]>[
    { name: 'Федеральный', id: SuperVisionLevelsEnum.Federal },
    { name: 'Региональный', id: SuperVisionLevelsEnum.Regional },
  ],

  registry_levels: <IDictionaryItem[]>[
    { name: 'Федеральный', id: 1, code: 'to' },
    { name: 'Региональный', id: 2, code: 'roiv' },
  ],

  VidResh: <IDictionaryItem[]>[
    { name: 'предоставление лицензии', id: 1 },
    { name: 'переоформление (продление срока действия)', id: 2 },
    { name: 'приостановление действия лицензии', id: 3 },
    { name: 'возобновление действия лицензии', id: 4 },
    { name: 'аннулирование лицензии', id: 5 },
    { name: 'признание лицензии утратившей силу', id: 6 },
    { name: 'ограничение действия лицензии (снятие ограничения)', id: 7 },
    { name: 'отзыв лицензии', id: 8 },
  ],

  PrDeistv: <IPrDeistv[]>[
    {
      name: 'лицензия выдана',
      id: 1,
      description: `лицензия выдана (возобновлена) на данный вид деятельности.
        При передаче сведений о выдаче лицензии - указывается в отношении каждого вида работ, на которые выдана лицензия.
        При передаче сведений о возобновлении действия лицензии - указывается в отношении вида деятельности,
        на которое не наложено ограничение.
        При передаче сведений о переоформлении лицензии - указывается в отношении видов работ,
        на которые была выдана исходная (переоформленная) лицензия и которые включены в новую (переоформленную) лицензию.
        При передаче сведений об ограничении действия лицензии - указывается в отношении видов работ,
        на которые ограничения не накладывались, или по передаваемому решению лицензирующего органа
        ранее наложенное ограничение на данный вид работ снимается;`,
    },
    {
      name: 'действие лицензии приостановлено',
      id: 2,
      description: `действие лицензии приостановлено (наложено ограничение) на данный вид деятельности.
        При передаче сведений о приостановлении действия лицензии - указывается в отношении каждого вида работ,
        на которые выдана лицензия, действие которой приостановлено.
        При передаче сведений об ограничении действия лицензии - указывается в отношении видов работ,
        на которые действие лицензии ограничено (приостановлено);`,
    },
    {
      name: 'лицензия прекращает действие на данный вид деятельности',
      id: 3,
      description: `лицензия прекращает действие на данный вид деятельности.
      При передаче сведений об аннулировании лицензии - указывается в отношении каждого вида работ, на которые была выдана лицензия.
      При передаче сведений о переоформлении лицензии - указывается в отношении видов работ,
      на которые была выдана исходная (переоформленная) лицензия и которые НЕ были включены в новую (переоформленную) лицензию;`,
    },
    {
      name: 'в лицензию добавлен новый вид работ',
      id: 4,
      description: `в лицензию добавлен новый вид работ.
        Передается только при передаче сведений о переоформлении лицензии в отношении видов работ,
        которые отсутствовали в исходной лицензии.`,
    },
  ],

  slvd: <IDictionaryItem[]>[
    { name: 'Разрешение СИТЕС', id: 1700323 },
    { name: 'Разрешение о возможности вывоза коллекционных материалов', id: 1700324 },
    { name: 'Лицензия на транзит отходов', id: 1700302 },
    { name: 'Разрешение на т/г перемещение ядовитых веществ', id: 1700305 },
    { name: 'Разрешение на т/г перемещение животных и растений', id: 1700320 },
    { name: 'Лицензия на работу с отходами', id: 1060100 },
    { name: 'Разрешение на добычу объектов животного и растительного мира КК РФ', id: 1750403 },
    { name: 'Разрешение на содержание и разведение объектов КК РФ', id: 1750408 },
    { name: 'Разрешение на оборот диких животных', id: 1750400 },
  ],

  payerType: <IPaymentsManagementGisGmpPayerTypeDictionaryItem[]>[
    { name: 'для ФЛ', id: EGisGmpPayerType.private },
    { name: 'для ЮЛ – резидента РФ', id: EGisGmpPayerType.legal_resident },
    { name: 'для ЮЛ – нерезидента РФ', id: EGisGmpPayerType.legal_non_resident },
    { name: 'для ИП', id: EGisGmpPayerType.individual },
  ],

  smev_statuses: <IDictionaryItem[]>[
    { id: SmevUIStatus.created, name: 'Ожидает отправки' },
    { id: SmevUIStatus.completed, name: 'Выполнен' },
    { id: SmevUIStatus.pending, name: 'Ожидание' },
    { id: SmevUIStatus.error, name: 'Ошибка' },
  ],

  has_accruals: [
    { id: false, name: 'Отсутствуют' },
    { id: true, name: 'Сформированы' },
  ],

  is_systemic: [
    { id: false, name: 'Не системные' },
    { id: true, name: 'Системные' },
  ],

  is_manual: [
    { id: 1, name: 'Ручные' },
    { id: 0, name: 'Автоматические' },
  ],

  yes_no: [
    { id: false, name: 'Нет' },
    { id: true, name: 'Да' },
  ],

  no_oro_export: [
    { id: true, name: 'Не осуществляется' },
    { id: false, name: 'Осуществляется' },
  ],

  received_transmitted: [
    { id: false, name: 'Получены' },
    { id: true, name: 'Переданы' },
  ],

  approve_woman: [
    { id: false, name: 'не утверждена' },
    { id: true, name: 'утверждена' },
  ],

  yes_no_digit: [
    { id: 0, name: 'Нет' },
    { id: 1, name: 'Да' },
  ],

  yes_no_null: [
    { id: 'null', name: 'Нет' },
    { id: 'notnull', name: 'Да' },
  ],

  gisgmp_form_type: [
    { id: 'by_uin', name: 'по УИН' },
    { id: 'by_inn_kpp', name: 'по реквизитам плательщика' },
    { id: 'by_territory_org', name: 'по получателю платежа' },
  ],

  yes_no_miss: [
    { id: 'false', name: 'Нет' },
    { id: 'true', name: 'Да' },
    { id: 'null', name: 'нет данных' },
  ],

  yes_no_not_apply: [
    { id: ECheckListAnswerValue.no, name: 'Нет' },
    { id: ECheckListAnswerValue.yes, name: 'Да' },
    { id: ECheckListAnswerValue.not_apply, name: 'Неприменимо' },
  ],

  yes_no_all: [
    { id: true, name: 'Да' },
    { id: false, name: 'Нет' },
    { id: 'null', name: 'Не указано' },
  ],

  declaration_status_done: [
    { id: 'terminated', name: 'Нет' },
    { id: 'declaration_created,on_registration,accepted', name: 'Да' },
  ],

  is_present: [
    { id: false, name: 'Отсутствие' },
    { id: true, name: 'Наличие' },
  ],

  is_exist: [
    { id: false, name: 'Отсутствует' },
    { id: true, name: 'Имеется' },
  ],

  is_non_resident: [
    { id: false, name: 'Резидент' },
    { id: true, name: 'Нерезидент' },
  ],

  is_resident: [
    { id: true, name: 'Резидент' },
    { id: false, name: 'Нерезидент' },
  ],

  is_xml_uploaded_to_eb: [
    { id: true, name: 'Загруженные' },
    { id: false, name: 'Незагруженные' },
  ],

  is_xml_rec_uploaded_to_eb: [
    { id: true, name: 'Загруженные' },
    { id: false, name: 'Незагруженные' },
  ],

  is_excluded: [
    { id: false, name: 'Состоит на учете' },
    { id: true, name: 'Снят с учета' },
  ],

  only_excluded_map: [{ id: 'only_excluded', name: 'Снят с учета' }],

  template_status: [
    { id: 'running', name: 'Выполняется' },
    { id: 'done', name: 'Готов' },
    { id: 'error', name: 'Ошибка' },
    { id: 'pending', name: 'Ожидает выполнения' },
  ],

  smev4_status: [
    { id: 'pending', name: 'Ожидает отправки' },
    { id: 'done', name: 'Выполнен' },
    { id: 'error', name: 'Ошибка' },
    // { id: 'running', name: 'Выполняется' },
  ],

  is_personally: [
    { id: true, name: 'Выдача на руки' },
    { id: false, name: 'Заказное письмо' },
  ],

  metting_place: [
    { id: 1, name: 'Зал Заседаний №1' },
    { id: 2, name: 'Зал Заседаний №2' },
    { id: 3, name: 'Зал Заседаний №3' },
    { id: 4, name: 'Зал Заседаний №4' },
  ],

  registry_category: [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
  ],

  tool_environment_type: [
    { id: 1, name: 'Воздух' },
    { id: 2, name: 'Вода' },
  ],

  onv_application_method_id: [
    { id: 1, name: 'В форме электронного документа, подписанного ЭП' },
    { id: 2, name: 'Лично на руки' },
    { id: 4, name: 'Посредством почтового отправления' },
    { id: 6, name: 'Из старой базы' },
  ],

  onv_application_method_id_lk: [
    { id: 1, name: 'В форме электронного документа, подписанного ЭП' },
  ],

  onv_emission_object_types: [
    { id: 'Point', name: MapGeometryType.Point },
    { id: 'LineString', name: MapGeometryType.LineString },
    { id: 'Polygon', name: MapGeometryType.Polygon },
    { id: 'MultiLineString', name: MapGeometryType.MultiLineString },
  ],

  onv_emission_object_type_static: [
    { id: 1, name: MapGeometryType.Point },
    { id: 2, name: MapGeometryType.LineString },
    { id: 3, name: MapGeometryType.Polygon },
    { id: 5, name: MapGeometryType.MultiLineString },
  ],

  onv_emission_object_types_new: [
    { id: 'LineString', name: MapGeometryType.LineString },
    { id: 'MultiLineString', name: MapGeometryType.MultiLineString },
    { id: 'Polygon', name: MapGeometryType.Polygon },
  ],

  onv_emission_object_type_static_new: [
    { id: 2, name: MapGeometryType.LineString },
    { id: 3, name: MapGeometryType.Polygon },
    { id: 5, name: MapGeometryType.MultiLineString },
  ],

  onv_measure_tools_id: [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: 'Другое' },
  ],

  onv_neutralization_tools_id: [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: 'Другое' },
  ],

  groro_exclusion_reason: [
    {
      id: 'Консервация объекта, оказывающего негативное воздействие на окружающую среду',
      name: 'Консервация объекта, оказывающего негативное воздействие на окружающую среду',
    },
    {
      id: 'Вступление в законную силу постановления по делу об административном правонарушении',
      name: 'Вступление в законную силу постановления по делу об административном правонарушении',
    },
  ],

  event_groups: [
    { id: 3, name: 'Код 3 - Совершенствование технологических процессов (включая переход на другие виды топлива, сырья и др.)' },
    { id: 5, name: 'Код 5 - Строительство и ввод в действие новых пылегазоочистных установок и сооружений' },
    { id: 7, name: 'Код 7 - Повышение эффективности существующих очистных установок (включая их модернизацию, реконструкцию и ремонт)' },
    { id: 9, name: 'Код 9 - Ликвидация источников загрязнения' },
    { id: 11, name: 'Код 11 - Перепрофилирование предприятия (цеха, участка) на выпуск другой продукции' },
    { id: 13, name: 'Код 13 - Прочие мероприятия' },
  ],

  report_year: [
    { id: 2019, name: 'Предыдущий год' },
    { id: 2020, name: 'Текущий год' },
  ],

  okpd_tnved: [
    { id: 'okpd2', name: 'Произведен' },
    { id: 'tnved', name: 'Импортирован' },
  ],

  calculation_method_type: [
    { id: 'Агрегирование', name: 'Агрегирование' },
    { id: 'Дифференциация', name: 'Дифференциация' },
  ],

  license_request_old_statuses: [
    { id: 0, name: 'Действующий' },
    { id: 1, name: 'Приостановлен' },
    { id: 2, name: 'Аннулирован' },
    { id: 3, name: 'Переоформлен' },
    { id: 4, name: 'Не действующий' },
  ],

  time_of_reporting: [
    { id: 0, name: '1 апреля текущего года' },
    { id: 1, name: '1 августа текущего года' },
    { id: 2, name: '5 декабря текущего года' },
  ],

  danger_class: [
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: '5', name: '5' },
  ],

  doc_type: [
    { id: EDocType.primary, name: 'Первичная' },
    { id: EDocType.corrected, name: 'Уточненная' },
  ],

  report_doc_type: [
    { id: EDocType.primary, name: 'Первичный' },
    { id: EDocType.corrected, name: 'Уточненный' },
  ],

  filter_doc_type: [
    { id: 'null', name: 'Не указан' },
    { id: EDocTypeForFilter.primary, name: 'Первичный' },
    { id: EDocTypeForFilter.corrected, name: 'Уточненный' },
  ],

  cites_certificate_import_permission_status: [
    { id: 1, name: 'Действующее' },
    { id: 0, name: 'Не действующее' },
  ],

  cites_certificate_import_permit_results: [
    { id: 1, name: 'Заполнен' },
    { id: 0, name: 'Не заполнен' },
  ],

  attestation_number: [
    { id: 1, name: 'Первичная' },
    { id: 2, name: 'Повторная' },
  ],

  emission_source_types_static: [
    { id: 1, name: 'Организованный' },
    { id: 2, name: 'Неорганизованный' },
  ],

  months: [
    { id: 1, name: 'Январь' },
    { id: 2, name: 'Февраль' },
    { id: 3, name: 'Март' },
    { id: 4, name: 'Апрель' },
    { id: 5, name: 'Май' },
    { id: 6, name: 'Июнь' },
    { id: 7, name: 'Июль' },
    { id: 8, name: 'Август' },
    { id: 9, name: 'Сентябрь' },
    { id: 10, name: 'Октябрь' },
    { id: 11, name: 'Ноябрь' },
    { id: 12, name: 'Декабрь' },
  ],

  payment_management_based_on_types: [
    { id: 1, name: 'Внесение платы за НВОС' },
    { id: 2, name: 'Экологический сбор' },
    { id: 3, name: 'Пени' },
    { id: 4, name: 'Другое' },
  ],

  payment_management_accruals_based_on: [
    { id: 'epgu', name: 'Из заявления ЕПГУ' },
    { id: 'hand', name: 'Ручные' },
    { id: 'nvos', name: 'Из ДПНВОС' },
    { id: 'eco', name: 'Из расчета экосбора' },
    { id: 'hand_on_gisgmp_payment', name: 'На основе платежа ГИС ГМП' },
    { id: 'hand_on_sufd_payment', name: 'На основе платежа СУФД' },
    { id: 'addcharge', name: 'Из требования о доначислении' },
    { id: 'auto_discount_accounted_for', name: 'Автоматически. Учтена скидка за оплату в установленный срок' },
  ],

  payment_management_accruals_based_on_view: [
    { id: 'hand', name: 'Вручную' },
    { id: 'nvos', name: 'Из ДПНВОС' },
    { id: 'eco', name: 'Из расчета экосбора' },
    { id: 'hand_on_gisgmp_payment', name: 'На основе платежа ГИС ГМП' },
    { id: 'hand_on_sufd_payment', name: 'На основе платежа СУФД' },
    { id: 'addcharge', name: 'Из требования о доначислении' },
    { id: 'auto_discount_accounted_for', name: 'Автоматически. Учтена скидка за оплату в установленный срок' },
    { id: 'epgu', name: 'Из заявления ЕПГУ' },
  ],

  payments_management_formation_method: <IPMAnalyticsReportCreationMode[]>[
    { id: 'auto', name: 'Автоматически' },
    { id: 'hand', name: 'Вручную' },
  ],

  payments_management_based_on_gis_gmp: [
    { id: 'gis_gmp', name: 'ГИС ГМП' },
    { id: 'sufd', name: 'СУФД' },
    { id: 'hand', name: 'Сформировано вручную' },
  ],

  payment_management_quarters: [
    { id: 1, name: '1-й квартал' },
    { id: 2, name: '2-й квартал' },
    { id: 3, name: '3-й квартал' },
    { id: 4, name: '4-й квартал' },
  ],

  payment_management_quarters_for_form: [
    { id: 'null', name: 'За весь отчетный год' },
    { id: 1, name: '1-й квартал' },
    { id: 2, name: '2-й квартал' },
    { id: 3, name: '3-й квартал' },
    { id: 4, name: '4-й квартал' },
  ],

  payment_management_quarters_with_null: [
    { id: 'null', name: 'Не указан' },
    { id: '1-й квартал', name: '1-й квартал' },
    { id: '2-й квартал', name: '2-й квартал' },
    { id: '3-й квартал', name: '3-й квартал' },
    { id: '4-й квартал', name: '4-й квартал' },
  ],

  payment_management_gis_gmp_statuses: [
    { id: EGisGmpStatus.registration_waiting, name: EGisGmpStatusText.registration_waiting },
    { id: EGisGmpStatus.registered, name: EGisGmpStatusText.registered },
    { id: EGisGmpStatus.not_registered, name: EGisGmpStatusText.not_registered },
    { id: EGisGmpStatus.cancellation_waiting, name: EGisGmpStatusText.cancellation_waiting },
    { id: EGisGmpStatus.decancellation_waiting, name: EGisGmpStatusText.decancellation_waiting },
    { id: EGisGmpStatus.canceled, name: EGisGmpStatusText.canceled },
  ],

  payment_management_gis_gmp_request_types: [
    { id: EGisGmpRequestType.reconcile, name: EGisGmpRequestTypeText.reconcile },
    { id: EGisGmpRequestType.unreconcile, name: EGisGmpRequestTypeText.unreconcile },
    { id: EGisGmpRequestType.service_provided, name: EGisGmpRequestTypeText.service_provided },
    { id: EGisGmpRequestType.annulment_service_provided, name: EGisGmpRequestTypeText.annulment_service_provided },
    { id: EGisGmpRequestType.reconciliation_results_request, name: EGisGmpRequestTypeText.reconciliation_results_request },
  ],

  sender_role: [
    {
      id: 1,
      name: `ГАН (главный администратор доходов бюджета, имеющий в своем ведении администраторов доходов бюджета и (или)
      осуществляющий функции и полномочия учредителя в отношении государственных (муниципальных) учреждений)`,
    },
    {
      id: 2,
      name: `ГАН (орган государственной власти субъектов Российской Федерации (орган местного самоуправления),
      обеспечивающий информационное взаимодействие с ГИС ГМП государственных (муниципальных) учреждений и (или)
      администраторов доходов бюджета)`,
    },
    { id: 3, name: 'АН (администратор доходов бюджета, главный администратор доходов бюджета)' },
    { id: 4, name: 'АН (государственное (муниципальное) учреждение)' },
    {
      id: 5,
      name: `ГАП (оператор по переводу денежных средств, обеспечивающий информационное взаимодействие с ГИС ГМП
      иных операторов по переводу денежных средств и (или) банковских платежных агентов (субагентов), и (или) платежных агентов)`,
    },
    {
      id: 6,
      name: `ГАП (орган государственной власти субъектов Российской Федерации (орган местного самоуправления),
      обеспечивающий информационное взаимодействие с ГИС ГМП финансовых органов и (или) местных администраций,
      и (или) государственных (муниципальных) учреждений, осуществляющих прием в кассу от плательщиков наличных денежных средств)`,
    },
    { id: 7, name: 'АП (оператор по переводу денежных средств)' },
    { id: 8, name: 'АП (организация почтовой связи)' },
    { id: 9, name: 'АП (финансовый орган)' },
    { id: 10, name: 'АП (местная администрация)' },
    { id: 11, name: 'АП (банковский платежный агент)' },
    { id: 12, name: 'АП (банковский платежный субагент)' },
    { id: 13, name: 'АП (платежный агент)' },
    {
      id: 14,
      name: `АП (государственное (муниципальное) учреждение и многофункциональный центр, осуществляющий прием в
      кассу от плательщиков наличных денежных средств)`,
    },
    {
      id: 15,
      name: `ГАЗ (уполномоченный многофункциональный центр, обеспечивающий информационное взаимодействие с ГИС ГМП
      многофункциональных центров)`,
    },
    {
      id: 16,
      name: `ГАЗ (орган государственной власти субъекта Российской Федерации (орган местного самоуправления),
      обеспечивающий информационное взаимодействие с ГИС ГМП многофункциональных центров и (или) органов государственной власти
      (органов местного самоуправления), обладающих правом получать информацию, содержащуюся в ГИС ГМП при предоставлении
      государственных (муниципальных) услуг и (или) выполнении государственных (муниципальных) функций, и не осуществляющих
      администрирование платежей, его территориальные органы; орган государственной власти (орган местного самоуправления),
      обладающий правом получать информацию, содержащуюся в ГИС ГМП, при предоставлении государственных (муниципальных)
      услуг и (или) выполнении государственных (муниципальных) функций, и не осуществляющий администрирование платежей,
      его территориальные органы)
    `,
    },
    { id: 17, name: 'АЗ (оператор единого портала)' },
    { id: 18, name: 'АЗ (оператор регионального портала)' },
    { id: 19, name: 'АЗ (многофункциональный центр)' },
    { id: 20, name: 'АП (органы управления государственными внебюджетными фондами Российской Федерации)' },
    {
      id: 22,
      name: `АЗ (орган государственной власти (орган местного самоуправления), обладающий правом получать
      информацию из ГИС ГМП при предоставлении государственных (муниципальных) услуг и (или) выполнении
      государственных (муниципальных) функций, и не осуществляющий администрирование платежей, его территориальные органы)`,
    },
    { id: 23, name: 'АП (территориальный орган Федерального казначейства)' },
    { id: 24, name: 'ГАН (уполномоченный орган, являющийся главным администратором доходов бюджета)' },
    { id: 25, name: 'ГАН (оператор системы «Электронный бюджет»)' },
    { id: 27, name: 'АН (уполномоченный орган, являющийся администратором доходов бюджета)' },
    { id: 28, name: 'ГАП (оператор системы «Электронный бюджет»)' },
    {
      id: 29,
      name: `ГАП (органы управления государственными внебюджетными фондами Российской Федерации,
      обеспечивающие взаимодействие с ГИС ГМП территориальных органов государственных внебюджетных
      фондов Российской Федерации);`,
    },
    {
      id: 30,
      name: `ГАП (уполномоченные многофункциональные центры, обеспечивающие информационное
      взаимодействие с ГИС ГМП многофункциональных центров)`,
    },
    { id: 31, name: 'ГАЗ (оператор системы «Электронный бюджет»)' },
    {
      id: 32,
      name: `ГАЗ (оператор ФГИС ЕГР ЗАГС, обеспечивающий информационное взаимодействие с ГИС ГМП
      органов, осуществляющих государственную регистрацию актов гражданского состояния`,
    },
    { id: 33, name: 'АН (уполномоченный орган)' },
  ],

  pollutants_markers: [
    { id: 'air', name: 'Выбросы в атмосферу' },
    { id: 'water', name: 'Сбросы сточных вод' },
    { id: 'waste', name: 'Размещение отходов' },
  ],

  source: [
    { id: 'stationary', name: UITextO.sources.stationary },
    { id: 'discharges', name: UITextO.sources.discharge },
  ],

  report_type: [
    { id: 'dpnvos', name: 'ДПНВОС, т/год' },
    { id: 'dvos', name: 'ДВОС, т/год' },
    { id: 'pek', name: 'ПЭК, т/год' },
    { id: 'two_tp', name: '2-ТП (воздух)' },
  ],

  report_type_onvos: [
    { id: 'two_tp', name: '2-ТП (воздух)' },
    { id: 'pek', name: 'Отчет ПЭК' },
    { id: 'dvos', name: 'ДВОС' },
    { id: 'dpnvos', name: 'ДПНВОС' },
  ],

  reason_names_search_types: [
    { id: 'emissions_stationary_sources_search_name', name: 'Источник выбросов' },
    { id: 'discharges_sources_search_name', name: 'Источник сбросов' },
    { id: 'waste_disposal_facilities_search_name', name: 'Источник размещения отходов' },
  ],

  monitoring_source_types: [
    { id: 'air', name: 'Источник выбросов' },
    { id: 'water', name: 'Источник сбросов' },
  ],

  payments_management_gis_gmp_request_status: [
    { id: EGisGmpRequestStatus.wait, name: EGisGmpRequestStatusText.wait },
    { id: EGisGmpRequestStatus.sent, name: EGisGmpRequestStatusText.sent },
    { id: EGisGmpRequestStatus.status_message, name: EGisGmpRequestStatusText.status_message },
    { id: EGisGmpRequestStatus.success, name: EGisGmpRequestStatusText.success },
    { id: EGisGmpRequestStatus.reject, name: EGisGmpRequestStatusText.reject },
    { id: EGisGmpRequestStatus.unknown_message_type, name: EGisGmpRequestStatusText.unknown_message_type },
  ],

  payments_management_gis_gmp_rrs_vs_status: [
    { id: 'by_uin', name: 'По УИН' },
    { id: 'by_inn_kpp', name: 'По данным плательщика' },
    { id: 'by_territory_org', name: 'По получателю платежа' },
  ],

  payments_management_gis_gmp_sac_meaning_status: [
    { id: 2, name: 'Уточнение начисления' },
    { id: 3, name: 'Аннуляция начисления' },
    { id: 4, name: 'Деаннуляция начисления' },
  ],

  payments_management_reestr: [
    { id: 'gis_gmp', name: 'ГИС ГМП' },
    { id: 'sufd', name: 'СУФД' },
  ],

  payments_management_collection_way: [
    { id: 1, name: 'Добровольный порядок' },
    { id: 2, name: 'По решению суда в добровольном порядке' },
    { id: 3, name: 'В рамках исполнительного производства' },
  ],

  payments_management_collection_status: [
    { id: 1, name: 'Взыскано' },
    { id: 2, name: 'На исполнении' },
    { id: 3, name: 'Направлено в ФССП' },
    { id: 4, name: 'В досудебном порядке исполнено' },
    { id: 5, name: 'На рассмотрении в Арбитражном суде' },
  ],

  renewal_number: [
    { id: 1, name: 'Первичная выдача' },
    { id: 2, name: 'Первое продление' },
    { id: 3, name: 'Второе продление' },
    { id: 4, name: 'Третье продление' },
  ],

  groro_request_type: [
    { name: 'Включение', id: 101 },
    { name: 'Актуализация', id: 102 },
    { name: 'Исключение', id: 103 },
    { name: 'Обновление', id: 105 },
  ],

  adjust_reasons: [
    {
      id: 'category',
      name: 'Изменение критериев отнесения объектов к объектам I, II, III и  IV категорий',
    },
    {
      id: 'duplicate',
      name: 'Выявление факта внесения в государственный реестр учетных сведений об одном и том же объекте 2 раза и более',
    },
    {
      id: 'disunion',
      name: 'Утрата объектом единства назначения и (или) неразрывной физической или технологической связанности',
    },
    {
      id: 'union',
      name: 'Объединение объектов по единому назначению и (или) установлению неразрывной физической или технологической связанности',
    },
    {
      id: 'mistake',
      name: 'Исправление допущенных описок, опечаток, арифметических ошибок в сведениях, содержащихся в государственном реестре',
    },
  ],

  smev3_process_status: [
    { id: BadgeStatus.new, name: 'Ожидает отправки' },
    { id: BadgeStatus.success, name: 'Ожидание' },
    { id: BadgeStatus.failure, name: 'Ошибка' },
    { id: BadgeStatus.complete, name: 'Выполнен' },
  ],

  smev_registry_mchs_units_reject: [
    { id: '001', name: 'Запись отсутствует в реестре аттестованных АСС(Ф)' },
    { id: '002', name: 'Отказа в предоставлении доступа к запрашиваемой информации)' },
    { id: '003', name: '-' },
    { id: '004', name: '-' },
    { id: '005', name: '-' },
  ],

  pm_reports_lk_state_service_ids: [
    { id: '610', name: 'Декларация о плате за НВОС (форма приказа 1043)' },
    { id: '611', name: 'Декларация о плате за НВОС (форма приказа приказа 624)' },
    { id: '59', name: 'Расчет экосбора' },
  ],

  ervk_status: [
    { id: EErvkStatusSync.sync, name: 'Синхронизировано', color: '#71BC90' },
    { id: EErvkStatusSync.null, name: 'Не синхронизировано', color: '#B3BBC3' },
    { id: EErvkStatusSync.not_need, name: 'Не требуется синхронизация', color: '#71BC90' },
    { id: EErvkStatusSync.error, name: 'Ошибка синхронизации', color: '#E53935' },
    { id: EErvkStatusSync.sended, name: 'Отправлено на синхронизацию', color: '#FF9C2B' },
  ],
  approval_type: [
    { id: 'serial', name: 'Последовательный' },
    { id: 'parallel', name: 'Параллельный' },
  ],
  npa_number: [
    { id: '87', name: '87' },
    { id: '251', name: '251' },
    { id: '434', name: '434' },
    { id: '498', name: '498' },
    { id: '610', name: '610' },
    { id: '728', name: '728' },
    { id: '871', name: '871' },
    { id: '1792', name: '1792' },
    { id: '1798', name: '1798' },
    { id: '2395-1', name: '2395-1' },
    { id: '7-ФЗ', name: '7-ФЗ' },
    { id: '89-ФЗ', name: '89-ФЗ' },
    { id: '96-ФЗ', name: '96-ФЗ' },
    { id: '155-ФЗ', name: '155-ФЗ' },
    { id: '174-ФЗ', name: '174-ФЗ' },
    { id: '187-ФЗ', name: '187-ФЗ' },
  ],
};
