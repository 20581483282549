export enum IconsSet {
  folder = 'folder',
  petition_form = 'petition_form',
  receipt = 'receipt',
  Federal = 'Federal',
  Regional = 'Regional',
  negativeCategory = 'negativeCategory',
  upload = 'upload',
  funnel = 'funnel',
  box = 'box',
  review = 'review',
  reqAdjustment = 'reqAdjustment',
  reqUpdate = 'reqUpdate',
  reqRemove = 'reqRemove',
  reqRegistration = 'reqRegistration',
  lock = 'lock',
  warning = 'warning',
  warningWithRoundCorners = 'warningWithRoundCorners',
  warningWithRoundCornersThin = 'warningWithRoundCornersThin',
  clipboardNeck = 'clipboardNeck',
  question = 'question',
  toggle = 'toggle',
  attention = 'attention',
  file = 'file',
  fileRounded = 'fileRounded',
  vector = 'vector',
  statusEmpty = 'statusEmpty',
  statusComplete = 'statusComplete',
  statusAttention = 'statusAttention',
  statusDirty = 'statusDirty',
  statusLock = 'statusLock',
  statusReadonly = 'statusReadonly',
  statusCompleteDisabled = 'statusCompleteDisabled',
  chevronDown = 'chevronDown',
  chevronDownNew = 'chevronDownNew',
  unknownUser = 'unknownUser',
  docSended = 'docSended',
  docError = 'docError',
  signSend = 'signSend',
  printPaper = 'printPaper',
  closeModal = 'closeModal',
  loader = 'loader',
  ok = 'ok',
  arrowBack = 'arrowBack',
  iconView = 'iconView',
  iconDownload = 'iconDownload',
  iconDownloadAlt = 'iconDownloadAlt',
  asi = 'asi',
  checkmark = 'checkmark',
  arrowLeft = 'arrowLeft',
  arrowRight = 'arrowRight',
  refresh = 'refresh',
  handset = 'handset',
  blindEye = 'blindEye',
  infoI = 'infoI',
  info = 'info',
  switchTable = 'switchTable',
  switchGrid = 'switchGrid',
  bigAttention = 'bigAttention',
  objectsLk = 'objectsLk',
  reportsLk = 'reportsLk',
  declarationLk = 'declarationLk',
  documentsLk = 'documentsLk',
  paymentsLk = 'paymentsLk',
  profileLk = 'profileLk',
  arrowLinkLk = 'arrowLinkLk',
  arrowTopRight = 'arrowTopRight',
  dottedVMenu = 'dottedVMenu',
  filter = 'filter',
  magnific = 'magnific',
  envelope = 'envelope',
  arrowTop = 'arrowTop',
  print = 'print',
  dots = 'dots',
  chevronRight = 'chevronRight',
  buttonIconPlus = 'buttonIconPlus',
  chevronLeft = 'chevronLeft',
  iconTrash = 'iconTrash',
  iconPie = 'iconPie',
  iconNoty = 'iconNoty',
  triangle = 'triangle',
  triangleOnCircle = 'triangleOnCircle',
  hexagonAttention = 'hexagonAttention',
  iconExit = 'iconExit',
  pencil = 'pencil',
  work = 'work',
  calculator = 'calculator',
  search = 'search',
  searchMini = 'searchMini',
  chevronUp = 'chevronUp',
  plant = 'plant',
  history = 'history',
  check = 'check',

  iconGroupValid = 'iconGroupValid',
  iconGroupEmpty = 'iconGroupEmpty',
  iconGroupHasError = 'iconGroupHasError',

  iconFormValid = 'iconFormValid',
  iconFormEmpty = 'iconFormEmpty',

  iconFormHasError = 'iconFormHasError',
  iconBranchHasError = 'iconBranchHasError',
  iconFormBranchHasError = 'iconFormBranchHasError',

  statusFormDisabled = 'statusFormDisabled',
  statusGroupDisabled = 'statusGroupDisabled',

  iconGroupValidActive = 'iconGroupValidActive',
  iconGroupEmptyActive = 'iconGroupEmptyActive',
  iconGroupHasErrorActive = 'iconGroupHasErrorActive',

  iconFormValidActive = 'iconFormValidActive',
  iconFormEmptyActive = 'iconFormEmptyActive',

  iconBranchHasErrorActive = 'iconBranchHasErrorActive',
  iconFormHasErrorActive = 'iconFormHasErrorActive',
  iconFormBranchHasErrorActive = 'iconFormBranchHasErrorActive',

  statusFormDisabledActive = 'statusFormDisabledActive',
  statusGroupDisabledActive = 'statusGroupDisabledActive',

  iconCopyToClipboard = 'iconCopyToClipboard',
  iconCopyToClipboard2 = 'iconCopyToClipboard2',
  iconUndo = 'iconUndo',

  iconLkLeftArrow = 'iconLkLeftArrow',
  strikeThrough = 'strikeThrough',
  textBold = 'textBold',
  textItalic = 'textItalic',

  iconNotificationInfo = 'iconNotificationInfo',
  email = 'email',
  factory = 'factory',
  reports = 'reports',
  catalog = 'catalog',
  guarantee = 'guarantee',
  TrueFederal = 'TrueFederal',
  map = 'map',
  sidebar = 'sidebar',
  sidebar_cross = 'sidebar_cross',
  check_mark = 'check_mark',

  ruler = 'ruler',
  renew = 'renew',
  area = 'area',
  edit = 'edit',
  point_editor = 'point_editor',
  reference = 'reference',

  templateButton = 'templateButton',
  templateManagement = 'templateManagement',

  sigma = 'sigma',

  checkCircle2 = 'checkCircle2',
  alertOctagon = 'alertOctagon',
  version = 'version',
  chemistry = 'chemistry',
  dataView = 'dataView',
  menu = 'menu',
  folder_move = 'folder_move',

  parallel = 'parallel',
  serial = 'serial',
  attachment = 'attachment',

  digitalSignature = 'digitalSignature',

  caretLeft = 'caretLeft',
  caretRight = 'caretRight',

  settings = 'settings',

  chartAvg = 'chartAvg',

  reply = 'reply',

  stopSign = 'stopSign',
  thumbsUp = 'thumbsUp',
  star = 'star',
}
