import { EnvironmentType } from 'src/app/providers/_const/environment.type';
import { UserScopes } from 'src/app/providers/_enum';
import { environment } from 'src/environments/environment';

interface IUserScopeParams {
  roles: string[];
  activator: (roles: string[]) => boolean;
}

export class UserScope {
  public parent: UserScope;
  public children: UserScope[] = [];
  public isActive: boolean;

  constructor(params: IUserScopeParams) {
    this.isActive = params.activator(params.roles);
  }

  public createChildren(params: IUserScopeParams) {
    const instance = new UserScope(params);
    instance.parent = this;

    if (!instance.parent.isActive) {
      instance.isActive = false;
    }

    this.children.push(instance);

    return instance;
  }
}

type IUserScopesRepo = {
  [K in UserScopes]: UserScope;
};

export class UserScopesRepo implements IUserScopesRepo {
  public admin = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'admin' === role) {
          return true;
        }
      }
    },
  });

  public ptk_ceo = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.PTK_CEO' === role) {
          return true;
        }
      }
    },
  });

  public user_profile_test =  new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'user_profile_test' === role) {
          return true;
        }
      }
    },
  });

  public PTK_PAY_CA_DASHBORD_DEMO = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'PTK_PAY_CA_DASHBORD_DEMO' === role) {
          return true;
        }
      }
    },
  });

  public PTK_PAY_TO_DASHBORD_DEMO = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'PTK_PAY_TO_DASHBORD_DEMO' === role) {
          return true;
        }
      }
    },
  });

  public fgen_roiv = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      if (!roles.includes('TREE_FGN_TO') && roles.includes('TREE_FGN_RIOV')) {
        return true;
      }
      return false;
    },
  });

  public admin_menu = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'admin_menu' === role) {
          return true;
        }
      }
    },
  });

  public staff = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'staff' === role) {
          return true;
        }
      }
    },
  });

  public state_services_administrator = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            '6538_test_role', // TODO: access to test stands (develop/testing)
            'RPRN00101.RPN_STRUCTURE_ADMINISTRATOR',
            'RPRN00101.STATE_SERVICES_ADMINISTRATOR',
            'RPRN00101.STATE_SERVICES_ADMINISTRATOR_RPN',
            'RPRN00101.STATE_SERVICES_ADMINISTRATOR_TERRITORY_ORG',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public search_demo = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['search-demo-ksv', 'admin', 'staff'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public search_demo_ksv = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'search-demo-ksv' === role) {
          return true;
        }
      }
    },
  });

  public demo = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'demo' === role) {
          return true;
        }
      }
    },
  });

  public demo_reestr = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'demo_reestr' === role) {
          return true;
        }
      }
    },
  });

  public TYPE_CONTROL_CA = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.TREE_PARAMETER_TYPE_CONTROL_CA' === role) {
          return true;
        }
      }
    },
  });

  public TYPE_CONTROL_TO = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.TREE_PARAMETER_TYPE_CONTROL_TO' === role) {
          return true;
        }
      }
    },
  });

  public ADD_PARAMETER_TYPE_CONTROL = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.TREE_ADD_PARAMETER_TYPE_CONTROL' === role) {
          return true;
        }
      }
    },
  });


  public sentries = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.SENTRIES' === role) {
          return true;
        }
      }
    },
  });

  public new_constructor = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.STATE_SERVICES'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public reest_liquidated_subsoil_to = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.REESTR_LIQUIDATED_SUBSOIL_TO', 'RPRN00101.REESTR_LIQUIDATED_SUBSOIL_CA'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public rosnedra_notice_ca = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.TREE_ROSNEDRA_NOTICE_CA' === role) {
          return true;
        }
      }
    }
  });

  public state_reestr_gou = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && [
          'admin',
          'RPRN00101.REESTR_GOU',
        ].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public state_services_quotes_gou_ekozos = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && [
          'admin',
          'RPRN00101.TREE_ROSNEDRA_NOTICE_CA',
          'RPRN00101.TREE_ROSNEDRA_NOTICE_TO',
          'RPRN00101.TREE_SERVICES_EKOZOS',
          'RPRN00101.REESTR_GOU',
          'RPRN00101.STATE_SERVICES_QUOTAS'
        ].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public state_services_quotes = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && [
          'admin',
          'RPRN00101.STATE_SERVICES_QUOTAS'
        ].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public internal_reports = new UserScope({
    roles: this.roles,
    activator: (roles) => {

      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.INTERNAL_REPORTS'].indexOf(role) >= 0) {
          return true;
        }
      }
    }
  });

  public analytics_report_designer_list = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            'RPRN00101.DATA_MART_ROP',
            'RPRN00101.DATA_MART_ROP_CA',
            'RPRN00101.DATA_MART_DPNVOS_CA',
            'RPRN00101.DATA_MART_ROP_TO',
            'RPRN00101.DATA_MART_DPNVOS_TO',
            'RPRN00101.DATA_MART_DVOS_CA',
            'RPRN00101.DATA_MART_DVOS_TO',

            'RPRN00101.PTK_TER_DPNVOS',
            'RPRN00101.PTK_CA_DPNVOS',
            'RPRN00101.TREE_ROP_TO',
            'RPRN00101.TREE_ROP_CA',

            'RPRN00101.PTK_TER_DPNVOS_CA',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public dpnvos = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            'RPRN00101.PTK_TER_DPNVOS',
            'RPRN00101.PTK_CA_DPNVOS',
            'RPRN00101.PTK_TER_DPNVOS_CA',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public fgen_object = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            'demo_reestr',
            'RPRN00101.TREE_FGEN_OBJECTS_TO',
            'RPRN00101.TREE_FGEN_OBJECTS_CA',
            'RPRN00101.TREE_FGN_CA',
            'RPRN00101.TREE_FGN_TO',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public fgan_object = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            'demo_reestr',
            'RPRN00101.TREE_FGAN_OBJECTS_TO',
            'RPRN00101.TREE_FGAN_OBJECTS_CA',
            'RPRN00101.TREE_FGN_CA',
            'RPRN00101.TREE_FGN_TO',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public fgahn_object = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            'demo_reestr',
            'RPRN00101.TREE_FGAHN_OBJECTS_TO',
            'RPRN00101.TREE_FGAHN_OBJECTS_CA',
            'RPRN00101.TREE_FGN_CA',
            'RPRN00101.TREE_FGN_TO',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public fggn_object = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            'demo_reestr',
            'RPRN00101.TREE_FGGN_OBJECTS_TO',
            'RPRN00101.TREE_FGGN_OBJECTS_CA',
            'RPRN00101.TREE_FGN_CA',
            'RPRN00101.TREE_FGN_TO',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public fgzn_object = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            'demo_reestr',
            'RPRN00101.TREE_FGZN_OBJECTS_TO',
            'RPRN00101.TREE_FGZN_OBJECTS_CA',
            'RPRN00101.TREE_FGN_CA',
            'RPRN00101.TREE_FGN_TO',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public fgohn_object = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            'demo_reestr',
            'RPRN00101.TREE_FGOHN_OBJECTS_TO',
            'RPRN00101.TREE_FGOHN_OBJECTS_CA',
            'RPRN00101.TREE_FGN_CA',
            'RPRN00101.TREE_FGN_TO',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public fgotn_object = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            'demo_reestr',
            'RPRN00101.TREE_FGOTN_OBJECTS_TO',
            'RPRN00101.TREE_FGOTN_OBJECTS_CA',
            'RPRN00101.TREE_FGN_CA',
            'RPRN00101.TREE_FGN_TO',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public fglesn_object = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            'demo_reestr',
            'RPRN00101.TREE_FGLESN_OBJECTS_TO',
            'RPRN00101.TREE_FGLESN_OBJECTS_CA',
            'RPRN00101.TREE_FGN_CA',
            'RPRN00101.TREE_FGN_TO',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });
  public fgen_recult = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'demo_reestr', 'RPRN00101.TREE_KNM_CA', 'RPRN00101.TREE_KNM_TO'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public tree_knd_to = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'demo_reestr', 'RPRN00101.TREE_KND_INSPECTOR_ТО', 'RPRN00101.TREE_KND_MANAGER_TO'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public nvos_waste_certification = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            'RPRN00101.TREE_FCAO_WASTE_PASSPORT_MANAGER',
            'RPRN00101.TREE_FCAO_WASTE_PASSPORT_EXECUTOR',
            'RPRN00101.TREE_TO_WASTE_PASSPORT',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public gee_view = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.PTK_GEE', 'RPRN00101.PTK_FORMS_GEE'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public envharm_view = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.PTK_GU_IRV_TO', 'RPRN00101.PTK_GU_IRV_CA'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public envharm_to = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.PTK_GU_IRV_TO' === role) {
          return true;
        }
      }
    },
  });

  public envharm_ca = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.PTK_GU_IRV_CA' === role) {
          return true;
        }
      }
    },
  });

  public lic_waste_view = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.TREE_LIC_TO', 'RPRN00101.TREE_LIC_CA', 'RPRN00101.TREE_LIC_ROIV'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public license_onsite_check_view = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.TREE_LIC_ONSITE_CHECK'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public documentary_check_view = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.TREE_LIC_DOCUMENTARY_CHECK'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public lic_waste_view_public = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          ['RPRN00101.TREE_LIC_TO', 'RPRN00101.TREE_LIC_CA', 'RPRN00101.TREE_LIC_ROIV', 'RPRN00101.TREE_LIC_REGISTRY_VIEW'].indexOf(role) >=
            0
        ) {
          return true;
        }
      }
    },
  });

  public cites_certificate_import = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          ['admin', 'RPRN00101.TREE_CITES_REGISTER', 'RPRN00101.TREE_CITES_REGISTER_CA', 'RPRN00101.TREE_CITES_REGISTER_TO'].indexOf(
            role,
          ) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public lic_waste_to = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.TREE_LIC_TO', 'RPRN00101.TREE_LIC_ROIV'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public lic_waste_ca = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.TREE_LIC_CA' === role) {
          return true;
        }
      }
    },
  });

  public groro_view = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'admin',
            'groro_demo',
            'RPRN00101.TREE_GRORO_TO',
            'RPRN00101.TREE_GRORO_CA',
            'RPRN00101.PTK_TER_GRORO_INSPECTOR',
            'RPRN00101.PTK_TER_GRORO_MANAGER',
            'RPRN00101.TREE_GRORO_TO_INSPECTOR',
            'RPRN00101.TREE_GRORO_TO_MANAGER',
          ].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public eek_register_view = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.TREE_EEK_REGISTER_CA', 'RPRN00101.TREE_EEK_REGISTER_TO'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public eek_petition_view = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.TREE_EEK_PETITION_CA', 'RPRN00101.TREE_EEK_PETITION_TO'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public eek_petition_lk_demo = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['eek_petition_test_lkpp'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public fkko = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.TREE_FKKO', 'RPRN00101.TREE_FKKO_TO'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public groro_to = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          ['admin', 'RPRN00101.TREE_GRORO_TO', 'RPRN00101.TREE_GRORO_TO_INSPECTOR', 'RPRN00101.TREE_GRORO_TO_MANAGER'].indexOf(role) >= 0
        ) {
          return true;
        }
      }
    },
  });

  public groro_ca = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.TREE_GRORO_CA' === role) {
          return true;
        }
      }
    },
  });

  public tree_rop_to = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.TREE_ROP_TO' === role) {
          return true;
        }
      }
    },
  });

  public ptk_ter_dpnvos = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.PTK_TER_DPNVOS' === role) {
          return true;
        }
      }
    },
  });

  public uonvos_olap = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.UONVOS_OLAP', 'admin'].includes(role)) {
          return true;
        }
      }
    },
  });

  public uonvos_private = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          (role &&
            role !== 'USER_UONVOS_PUBLIC' &&
            (role.toLowerCase().indexOf('nvos') >= 0 ||
              role.toLowerCase().indexOf('pto') >= 0 ||
              role.toLowerCase().indexOf('uonvos') >= 0)) ||
          environment.SYSTEM_TYPE === EnvironmentType.lk
        ) {
          return true;
        }
      }

      return false;
    },
  });

  public uonvos_public = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'USER_UONVOS_PUBLIC' === role) {
          return true;
        }
      }
      return false;
    },
  });

  public smev_admin = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          ['RPRN00101.PTK_SMEV_ADMIN', 'admin', 'PTK_SMEV_TEST_6598', 'RPRN00101.PTK_SMEV_UNIF'].indexOf(role) >= 0 &&
          (environment.SYSTEM_TYPE === EnvironmentType.ksv || environment.SYSTEM_TYPE === EnvironmentType.onvos)
        ) {
          return true;
        }
      }
    },
  });

  public smev_view = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'RPRN00101.PTK_SMEV_ADMIN',
            'RPRN00101.PTK_SMEV_CENT_AP',
            'RPRN00101.PTK_SMEV_TER_ORG',
            'PTK_SMEV_TEST_6598',
            'RPRN00101.PTK_SMEV_UNIF',
          ].indexOf(role) >= 0 &&
          (environment.SYSTEM_TYPE === EnvironmentType.ksv || environment.SYSTEM_TYPE === EnvironmentType.onvos)
        ) {
          return true;
        }
      }
    },
  });

  public smev_send = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (
          role &&
          [
            'RPRN00101.PTK_SMEV_SEND',
            'RPRN00101.PTK_SMEV_ADMIN',
            'RPRN00101.PTK_SMEV_CENT_AP',
            'RPRN00101.PTK_SMEV_TER_ORG',
            'PTK_SMEV_TEST_6598',
            'RPRN00101.PTK_SMEV_UNIF',
          ].indexOf(role) >= 0 &&
          environment.SYSTEM_TYPE === EnvironmentType.ksv
        ) {
          return true;
        }
      }
    },
  });

  public dkt = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if ((role && 'RPRN00101.PTK_DKT' === role) || role.toLowerCase().indexOf('dkt') >= 0) {
          return true;
        }
      }
    },
  });

  public utilization = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if ((role && 'RPRN00101.PTK_UTILIZATION' === role) || role.toLowerCase().indexOf('utilization') >= 0) {
          return true;
        }
      }
    },
  });

  public ecocharge = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if ((role && 'RPRN00101.PTK_ECOCHARGE' === role) || role.toLowerCase().indexOf('ecocharge') >= 0) {
          return true;
        }
      }
    },
  });

  public payments_management_demo = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return roles.indexOf('RPRN00101.PTK_PAY_DEMO') >= 0;
    },
  });

  public payments_management_admin_ca = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return roles.indexOf('admin') >= 0 || roles.indexOf('RPRN00101.PTK_PAY_CA') >= 0;
    },
  });

  public payments_management_admin_demo = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return roles.indexOf('admin') >= 0 || roles.indexOf('RPRN00101.PTK_PAY_DEMO') >= 0;
    },
  });

  public payments_management_admin_ca_to = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return roles.indexOf('admin') >= 0 || roles.indexOf('RPRN00101.PTK_PAY_CA') >= 0 || roles.indexOf('RPRN00101.PTK_PAY_TO') >= 0;
    },
  });

  public payments_management_admin_ca_to_demo = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return (
        roles.indexOf('admin') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_CA') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_DEMO') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_TO') >= 0
      );
    },
  });

  public payments_management_admin_ca_to_caview_demo = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return (
        roles.indexOf('admin') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_CA') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_TO') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_CA_VIEW') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_DEMO') >= 0
      );
    },
  });

  public payments_management_admin_ca_caview_to = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return (
        roles.indexOf('admin') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_CA') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_CA_VIEW') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_TO') >= 0
      );
    },
  });

  public payments_management_ca_caview_to = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return (
        roles.indexOf('admin') < 0 &&
        (roles.indexOf('RPRN00101.PTK_PAY_CA') >= 0 ||
          roles.indexOf('RPRN00101.PTK_PAY_CA_VIEW') >= 0 ||
          roles.indexOf('RPRN00101.PTK_PAY_TO') >= 0)
      );
    },
  });

  public payments_management_admin_demo_ca_caview = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return (
        roles.indexOf('RPRN00101.admin') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_DEMO') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_CA') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_CA_VIEW') >= 0
      );
    },
  });

  public payments_management_ca = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return roles.indexOf('admin') < 0 && roles.indexOf('RPRN00101.PTK_PAY_CA') >= 0;
    },
  });

  public payments_management_caview = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return roles.indexOf('admin') < 0 && roles.indexOf('RPRN00101.PTK_PAY_CA') < 0 && roles.indexOf('RPRN00101.PTK_PAY_CA_VIEW') >= 0;
    },
  });

  public payments_management_to = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return (
        roles.indexOf('admin') < 0 &&
        roles.indexOf('RPRN00101.PTK_PAY_CA') < 0 &&
        roles.indexOf('RPRN00101.PTK_PAY_CA_VIEW') < 0 &&
        roles.indexOf('RPRN00101.PTK_PAY_TO') >= 0
      );
    },
  });

  public PTK_PAY_FGBU = new UserScope({
    roles: this.roles,
    activator: (roles) => roles.some(r => r?.includes('RPRN00101.PTK_PAY_FGBU')),
  });

  public payments_management_to_ca_admin = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return (
        roles.indexOf('RPRN00101.PTK_PAY_CA') >= 0 ||
        roles.indexOf('admin') >= 0 ||
        roles.indexOf('RPRN00101.PTK_PAY_TO') >= 0
      );
    },
  });

  public payments_managment = new UserScope({
    roles: this.roles,
    activator: () => {
      // Пока что просто проверка на КСВ
      return false;
    },
  });

  public ker = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ('RPRN00101.PTK_GU_KER' === role || role === 'admin')) {
          return true;
        }
      }
    },
  });

  public public_inspector_request = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && (role.toLowerCase().indexOf('tree_pir') >= 0 || role === 'admin')) {
          return true;
        }
      }
    },
  });

  public eroi = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.TREE_EROI_OBJECT_ROIV', 'RPRN00101.TREE_EROI_OBJECT_TO', 'RPRN00101.TREE_EROI_OBJECT_CA'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public atex = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.TREE_ATEX_OBJECT_TO', 'RPRN00101.TREE_ATEX_OBJECT_CA'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public two_tp_demo_lk = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'two-tp-demo-lk' === role) {
          return true;
        }
      }
    },
  });

  public two_tp_demo_ksv = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'two-tp-demo-ksv' === role) {
          return true;
        }
      }
    },
  });

  public twotpGreenhouseGases = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['demo_greenhouse_user', 'demo_greenhouse_to'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public rpn_lk = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.LK_FSRPN_RU' === role) {
          return true;
        }
      }
    },
  });

  public default_email = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'default-email' === role) {
          return true;
        }
      }
    },
  });

  public default_esia = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'default-esia' === role) {
          return true;
        }
      }
    },
  });

  public recycler = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.TREE_RECYCLER_FCAO', 'RPRN00101.TREE_RECYCLER_TO', 'RPRN00101.TREE_RECYCLER_CA'].includes(role)) {
          return true;
        }
      }
    },
  });

  public recyclerFCAO = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.TREE_RECYCLER_FCAO'].includes(role)) {
          return true;
        }
      }
    },
  });

  public importXML = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.PTK_XML' === role) {
          return true;
        }
      }
    },
  });

  public ecochargeManualCreate = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'ecocharge_manual_create' === role) {
          return true;
        }
      }
    },
  });

  public ptk_inspections = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.KND', 'RPRN00101.PTK_INSPECTIONS'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public knd_references = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.KND_METODIKA'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public ptk_uonvos = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && 'RPRN00101.UONVOS' === role) {
          return true;
        }
      }
    },
  });

  public contorlled_entities_view = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.TREE_ENTITY_REGISTRY_VIEW', 'RPRN00101.TREE_ENTITY_REGISTRY_UPDATE'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public contorlled_entities_edit = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.TREE_ENTITY_REGISTRY_UPDATE'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public animal_registry = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.TREE_ANIMALS_TO', 'RPRN00101.TREE_ANIMALS_CA'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public check_lists_LK = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['CheckListsLK'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public tree_report_admin = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.TREE_REPORTS_ADMIN'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public demo_eek = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['demo_eek'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public tree_ozon_register = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.TREE_OZON_REGISTER'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public tree_ozon_register_all = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.TREE_OZON_REGISTER_ALL'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public tree_lic_fcao = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.TREE_LIC_FCAO'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public emissions_monitoring = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.UONVOS_EMISSIONS_MONITORING'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public emissions_manager = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['RPRN00101.UONVOS_EMISSIONS_MANAGER'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public admin_demo_pmDemo = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'demo', 'RPRN00101.PTK_PAY_DEMO'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public PTK_EXPLANATORY_DOCUMENTS = new UserScope({
    roles: this.roles,
    activator: (roles) => roles.includes('RPRN00101.PTK_EXPLANATORY_DOCUMENTS'),
  });

  public pm_analytics_form_2_3_4 = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      return (
        roles.indexOf('admin') >= 0 || roles.indexOf('RPRN00101.PTK_PAY_DEMO') >= 0 || roles.indexOf('demo') >= 0 ||
        ( (roles.indexOf('RPRN00101.PTK_PAY_TO') >= 0 || roles.indexOf('RPRN00101.PTK_PAY_CA') >= 0) && (roles.indexOf('RPRN00101.PTK_PAY_DEMO') >= 0 || roles.indexOf('demo') >= 0) )
      );
    },
  });

  public dfr_requests = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.TREE_ROP_TO', 'RPRN00101.TREE_ROP_CA'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public messages = new UserScope({
    roles: this.roles,
    activator: (roles) => {
      for (const role of roles) {
        if (role && ['admin', 'RPRN00101.PTK_MESSAGES'].indexOf(role) >= 0) {
          return true;
        }
      }
    },
  });

  public CORPORATE_PORTAL = new UserScope({
    roles: this.roles,
    activator: (roles) => roles.includes('RPRN00101.CORPORATE_PORTAL'),
  });

  public CORPORATE_PORTAL_ADMIN = new UserScope({
    roles: this.roles,
    activator: (roles) => roles.includes('RPRN00101.CORPORATE_PORTAL_ADMIN'),
  });

  constructor(private roles: string[]) {}
}
