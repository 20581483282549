import { Injectable } from '@angular/core';
import { isFunction } from 'lodash';
import { of, throwError, Observable } from 'rxjs';
import { switchMap, map, catchError, finalize, expand, takeWhile, delay, filter } from 'rxjs/operators';
import { DICT, DictionaryName, DictionaryDaDataName } from 'src/app/providers/_dictionary';
import { DictionaryStorage, IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';
import { IPagination } from 'src/app/providers/_interfaces/pagination.interface';
import { DadataService } from 'src/app/providers/_services/dadata.service';
import { SvcRestService, RpnHttpParams } from 'src/app/providers/_services/svc.rest.service';
import { getHash } from 'src/app/providers/_utils/utils';
import { addIdToUrlWithQueryParams, deepClone } from 'src/app/providers/_utils/utils';
const expiryTimeLive = 120; // min

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  constructor(
    private svcRestService: SvcRestService,
    private dadataService: DadataService,
  ) {}

  public setDictionaryAsExpired(dicts: typeof DICT, dictName: keyof typeof DICT): void {
    const dict = dicts[dictName];
    dict.expiryDate = 0;
    try {
      localStorage.removeItem('' + dictName);
    } catch (err) {}
  }

  public getDictionaryStorage<A extends typeof DICT, B extends DictionaryName>(dicts: A, dictName: B, children?: DictionaryStorage<any>) {
    const dict = dicts[dictName];

    if (dict && dict.dictURL) {
      if (!dict.loading) {
        dict.loading = true;
        const filters = dict.filters || {};
        let value: any[] = dict.dictionary$.getValue() || [];
        let expiryDate: number = dict.expiryDate || 0;
        if (!value.length || expiryDate < Date.now()) {
          try {
            const obj = JSON.parse(localStorage.getItem('' + dictName) || '{}');
            value = obj?.data?.length ? obj.data : [];
            expiryDate = +(obj?.expiryDate || 0);
          } catch (err) {
            console.error(err);
            value = [];
          }
          if (!value.length || expiryDate < Date.now()) {
            let page = 0;
            let allFinished = false;
            const result: any[] = [];

            const loadNewBatch$ = () => {
              page++;

              if (page > 1 && !dict.needLoadAllItems) {
                allFinished = true;
              }

              return of(allFinished).pipe(
                delay(1000),
                switchMap((finished: boolean) => {
                  const pagination = { per_page: 100, page };
                  return finished
                    ? throwError('All Finished')
                    : this.svcRestService.fetchDictionary(dict.dictURL, { filters, pagination }).pipe(
                        map((response) => {
                          if (response.data && dict.transform && isFunction(dict.transform)) {
                            return {
                              ...response,
                              data: dict.transform(response.data),
                            };
                          }
                          return response;
                        }),
                      );
                }),
                map(({ data, current_page, last_page }) => {
                  result.push(...data);
                  allFinished = current_page >= last_page || current_page !== page;
                  return of(allFinished);
                }),
                catchError(() => throwError('Non-Error exception captured')),
                finalize(() => {
                  expiryDate = Date.now() + expiryTimeLive * 60 * 1000;
                  // проверка на то что в справочнике одна страниц
                  if (result?.length && (result.length < 100 || dict.needLoadAllItems)) {
                    dict.needLoadAllItems = true;
                    try {
                      localStorage.setItem(dictName as string, JSON.stringify({ data: result, expiryDate }));
                    } catch (err) {}
                  }
                  dict.dictionary$.next(result);
                  dict.expiryDate = expiryDate;
                  dict.loading = false;

                  if (children) {
                    children.dictionary$.next(children.map(result));
                  }
                }),
              );
            };

            loadNewBatch$()
              .pipe(
                expand(() => loadNewBatch$()),
                takeWhile(() => !allFinished),
              )
              .subscribe();
          } else {
            dict.dictionary$.next(value);
            dict.expiryDate = expiryDate;
            dict.loading = false;

            if (children) {
              children.dictionary$.next(children.map(value));
            }
          }
        } else {
          dict.loading = false;
        }
      }
    } else if (dict && dict.parentName) {
      this.getDictionaryStorage(dicts, dict.parentName, dict);
    }

    return dicts[dictName];
  }

  public fetchDictionaryItems(itemName: DictionaryName, params: RpnHttpParams = {}): Observable<IDictionaryItem[]> {
    const dict = DICT[itemName];

    if (dict && dict.dictURL) {
      let itemUrl = dict.dictURL;

      if (dict?.beforeRequestMod && typeof dict.beforeRequestMod === 'function') {
        const mod = dict.beforeRequestMod(params, itemUrl);
        itemUrl = mod.url;
        params = mod.params;
      }

      if (itemUrl) {
        return this.svcRestService.httpGetWithCache(itemUrl, params).pipe(
          map((res) => res?.data || []),
          map((data) => {
            if (DICT[itemName].transform && isFunction(DICT[itemName].transform)) {
              return DICT[itemName].transform(data);
            }
            return data;
          }),
        );
      }
      return of([]);
    }

    return throwError(`${itemName} not found`);
  }

  public fetchDictionaryItemsByIdDefValue<T>(
    id: string | number,
    dictKey: DictionaryName,
    params: RpnHttpParams = {},
    defValue: T,
  ): Observable<IDictionaryItem | T> {
    if (!id) {
      return of(defValue);
    }

    return this.fetchDictionaryItemsById(id, dictKey, params);
  }

  public fetchDictionaryItemsById<T = IDictionaryItem>(
    idValue: string | number,
    dictKey: DictionaryName,
    params: RpnHttpParams = {},
    idName = 'id',
  ): Observable<T | null> {
    if (idValue === null || idValue === undefined) return of(null); // Если запросили не существующий id

    const dict = DICT[dictKey];

    if (dict) {
      // Проверка на существование справочника
      const dictArrayValue = dict.dictionary$.value;
      const isStaticDict = dictArrayValue?.length && !dict.dictURL;
      const isDictWithCache = dict.needLoadAllItems;

      if (isStaticDict) {
        // Статический
        return of(dictArrayValue).pipe(
          filter((x) => !!x),
          map((result: any[]) => result.find((item) => '' + item[idName] === '' + idValue)),
          switchMap((res) => {
            if (res) return of(res);
            return this.fetchDictValueFromServerById(dict, dictKey, idValue, params); // Запрашиваем с сервера по id
          }),
        );
      }

      if (isDictWithCache) {
        // Кэшируемый
        return (this.getDictionaryStorage(DICT, dictKey).dictionary$ as Observable<IDictionaryItem[]>).pipe(
          filter((x) => !!x),
          map((result: any[]) => result.find((item) => '' + item[idName] === '' + idValue)),
        );
      }

      return this.fetchDictValueFromServerById(dict, dictKey, idValue, params); // Запрашиваем с сервера по id
    }

    return throwError(`${dictKey} not found`);
  }

  public fetchDictValueFromServerById<T>(dict: DictionaryStorage<T>, dictKey: string, idValue: string | number, params: RpnHttpParams) {
    let itemUrl = dict.dictItemURL;

    if (itemUrl && idValue) {
      itemUrl = addIdToUrlWithQueryParams(itemUrl, idValue);

      if (typeof dict.beforeRequestMod === 'function') {
        const mod = dict.beforeRequestMod(params, itemUrl);
        itemUrl = mod.url;
        params = mod.params;
      }

      if (itemUrl) return this.svcRestService.httpGetWithCache(itemUrl, params);
    } else {
      console.log(`For ${dictKey} not found dictItemURL`);
    }

    return of(null);
  }

  private filterDict(dict: IDictionaryItem[], keyName: string, selected: string): IDictionaryItem[] {
    const dictHash = getHash(dict, keyName);
    const dictRes: IDictionaryItem[] = [];

    if (dictHash[selected]) {
      dictRes.push(dictHash[selected]);
    } else {
      Object.keys(dictHash).forEach((key) => {
        const selectedValues = selected.split(',');
        if (selectedValues.indexOf('' + dictHash[key][keyName]) >= 0) {
          dictRes.push(dictHash[key]);
        }
      });
    }

    return dictRes;
  }

  public fetchDictionaryItemsByKeys<T = IDictionaryItem>(
    ids: string[],
    itemName: DictionaryName,
    keyName: string,
    params: RpnHttpParams = {},
  ): Observable<IPagination<T> | null> {
    const dict = DICT[itemName];

    let url = dict?.dictURL;

    if (url) {
      const selected = ids.join(',');

      let newParams: RpnHttpParams = { ...params, filters: { ...{ [keyName]: selected }, ...(params.filters || {}) } };

      if (url && typeof dict.beforeRequestMod === 'function') {
        const mod = dict.beforeRequestMod(newParams, url);
        newParams = mod.params;
        url = mod.url;
      }

      if (url) {
        return this.svcRestService.httpGetWithCache(url, newParams).pipe(
          filter((x) => !!x),
          map((response) => {
            const transform = dict.transform;
            const data = this.filterDict(response.data, keyName, selected);
            if (transform && isFunction(transform)) {
              return { ...response, data: transform(data) };
            }
            return { ...response, data };
          }),
        );
      } else {
        return of(null);
      }
    } else if (dict) {
      return (dict.dictionary$ as Observable<IDictionaryItem[]>).pipe(
        filter((x) => !!x?.length),
        map((dictionaries: IDictionaryItem[]) => {
          const data: any[] = [];

          for (const id of ids) {
            data.push(...this.filterDict(dictionaries, keyName, id));
          }

          return { data, per_page: 20, current_page: 1, last_page: 1 };
        }),
      );
    }

    return throwError(`${itemName} not found`);
  }

  public getDadataSuggest(searchKey: string, type: DictionaryDaDataName): Observable<{ suggestions: any[] }> {
    return searchKey?.length > 299
      ? of({ suggestions: [] })
      : this.dadataService.getDadataSuggest(searchKey, type).pipe(catchError(() => of({ suggestions: [] })));
  }

  // Use only for dictionary with cache off
  public searchItems(
    dictKey: DictionaryName,
    value: string,
    paramsValue: RpnHttpParams,
    sendKey: string,
    searchType: string,
    per_page = 25,
  ): Observable<IDictionaryItem[]> {
    const params: RpnHttpParams = deepClone(paramsValue || {});

    if (value) {
      let key = 'search';
      if (searchType) key = searchType.indexOf('[') < 0 ? `${searchType}[${sendKey}]` : searchType;
      if (!params.filters) params.filters = {};
      params.filters.search = { key, value };
    }

    params.pagination = { per_page, page: 1 };

    return this.fetchDictionaryItems(dictKey, params);
  }

  public clearCache() {
    this.svcRestService.clearCache();
  }
}
